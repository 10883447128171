const deCoachingSportPsychology = {
    "coaching.offer.sportPsychology.title": "Sportpsychologie",
    "coaching.offer.sportPsychology.introduction": "Die Sportpsychologie ist ein wissenschaftliches Fach an der Schnittstelle von Psychologie, Sportwissenschaft und Medizin. Ihre Inhalte sind die Erforschung der psychologischen Grundlagen, der Abläufe im Sport und der Effekte des Sports, um daraus wissenschaftlich begründete Trainingsmaßnahmen zur Optimierung des Verhaltens im Sport ableiten zu können (Beckmann & Elbe, 2011). ",
    "coaching.offer.sportPsychology.myOfferings.introduction.1": "Alle angebotenen Leistungen sind an alle leistungsorientierte Athletinnen und Athleten gerichtet. Ein Mindestalter für sportpsychologisches Coaching gibt es nicht.",
    "coaching.offer.sportPsychology.myOfferings.introduction.2": "Meine Leistungen befassen sich mit folgendem sportpsychologischen Spektrum:",
    "coaching.offer.sportPsychology.myOfferings.sportPsychologySingleCoaching.title": "Sportpsychologisches Einzelcoaching",
    "coaching.offer.sportPsychology.myOfferings.sportPsychologySingleCoaching.description": "Hierbei geht es um das Eins –zu – Eins Coaching, in welchem zusammen mit dem Klienten/ der Klientin an der zuvor festgelegten Zielsetzung gearbeitet wird. Dabei können sowohl sportartspezifische Bewegungsausübungen (z. B. eine Vorhand- oder Freistoßtechnik) als auch mentale Techniken trainiert werden.",
    "coaching.offer.sportPsychology.myOfferings.dataAnalytics.title": "Datenanalyse und sportpsychologische Diagnostik",
    "coaching.offer.sportPsychology.myOfferings.dataAnalytics.description": "Es kann bei Vollständigkeit eines bestimmten Datensatzes, eine statistische Auswertung dieser Daten erfolgen. Die hierbei vorliegenden Daten werden mittels mathematisch – statistischen Verfahren ausgewertet und das Ergebnis Ihnen dann zur Verfügung gelegt. Hierbei kann es sich sowohl um die Auswertung sportbezogener Daten handeln wie z. B. die Erfolgskalkulation bei Umstellung der Teamaufstellung als auch um die Auswertung selbst gestellter sportpsychologischer diagnostischer Auswertungsverfahren.",
    "coaching.offer.sportPsychology.myOfferings.trainingCare.title": "Trainings-/ Trainingslager- / Wettkampfbetreuung",
    "coaching.offer.sportPsychology.myOfferings.trainingCare.description": "Bei der Trainings- und Wettkampfbetreuung wird die beratungsnehmende Person beim Training, Lehrgang, Wettkampf oder Ähnlichem Event beobachtet, sportpsychologisch analysiert sowie die mentale Leistung der jeweiligen Person evaluiert und besprochen. Nach einer solchen Betreuung bekommt der Athlet/ die Athletin einen Leistungsbericht über die gesamte Zeit der Betreuung.",
    "coaching.offer.sportPsychology.myOfferings.workshops.title": "Sportpsychologische Workshops/ Seminare",
    "coaching.offer.sportPsychology.myOfferings.workshops.description": "Folgende Themenbereiche werden von mir angeboten: Zielsetzungstraining, Relaxationstraining, Kriseninterventionen, Konzentrationstraining, Umgang mit Druck, Imaginationstraining, Umfeldmanagement, Achtsamkeitstraining, Identifikationstraining. Weitere Themenbereiche können nach Absprache ebenfalls ausgearbeitet werden.",
    "coaching.offer.sportPsychology.myOfferings.teambuilding.title": "Teambuilding",
    "coaching.offer.sportPsychology.myOfferings.teambuilding.description": "Beim Teambuilding geht es darum die Teamchemie der gesamten Mannschaft zu fördern, um diese im Wettkampf erfolgreich nutzen zu können. Teambuilidngs können in den vereinsinternen Räumen stattfinden, oder nach Absprache auch extern gehalten werden.",
    "coaching.offer.sportPsychology.myOfferings.coachTheCoach.title": "Coach the Coach",
    "coaching.offer.sportPsychology.myOfferings.coachTheCoach.description": "Hierbei werden hauptsächlich die psychosozialen Kompetenzen eines Trainers/ einer Trainerin betreut und optimiert. Das allgemeine Ziel bei diesem Angebot ist es die Leistung des Teams oder einer Einzelperson durch das Stärken eigener Kompetenzen zu maximieren.",
    "coaching.offer.sportPsychology.myOfferings.parentalCoaching.title": "Elterncoaching",
    "coaching.offer.sportPsychology.myOfferings.parentalCoaching.description": "Die Begleitung einer Sportkarriere des eigenen Kindes ist ein wichtiger Faktor bei der Potentialentfachung des Athleten/ der Athletin. Beim Elterncoaching wird die Grundlage geschaffen mit Drucksituationen umgehen zu können oder auch sich effizient für das eigene Kind einsetzen können.",
    "coaching.offer.sportPsychology.cooperation.description.1": "Eine sportpsychologische Sitzung beträgt in der Regel 50 Minuten kann jedoch nach Absprache auch länger dauern. Angebote wie die Trainings-/ Trainingslager- / Wettkampfbetreuung, welche sich außerhalb des vereinbarten Coachingraumes befinden, können auch ganztags bzw. über mehrere Tage stattfinden Der Coachingumfang, also die Anzahl der Sitzungen, ist individuell nach den Zielen und Wünschen des Athleten/ der Athletin wählbar.",
    "coaching.offer.sportPsychology.cooperation.description.2": "Es werden Räume zur sportpsychologischen Betreuung von mir angeboten, es ist jedoch möglich sich auch in anderen Räumlichkeiten zu treffen, solang der gesicherte Rahmen für eine solche Betreuung gewährleistet werden kann.",
    "coaching.offer.sportPsychology.procedure.description": "Zu Beginn jeder Betreuung werden die Ziele und Wünsche des Athleten/ der Athletin in einem Erstgespräch besprochen und vereinbart. Hierbei werden die Formalitäten um die Betreuung herum erklärt und zusammen ein Betreuungskonzept entworfen. In den Folgesitzungen wird dann in regelmäßigen Abständen der Fortschritt des Athleten/ der Athletin mittels der Dokumentation und durch sportpsychologische Testdiagnostik überprüft, um über die ganze Zeit effizient an den zuvor festgesetzten Zielen zu arbeiten."
}
export default deCoachingSportPsychology;