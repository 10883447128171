const deCV = {
    "cv.title": "Lebenslauf",
    "cv.today": "heute",
    "cv.tuDresden.title": "Technische Universität Dresden",
    "cv.tuDresden.text.1": "Doktorandenstudium an der medizinischen Fakultät Carl Gustav Carus.",
    "cv.tuDresden.text.2": "Angestrebter Titel: Dr. rer. medic.",
    "cv.tuDresden.text.3": "Forschung im Bereich Suizidalität im professionellem Fußball",
    "cv.dinklar.title": "Gesellschaft für Verhaltenstherapie Dinklar",
    "cv.diklar.text.1": "Psychologischer Psychotherapeut in Ausbildung",
    "cv.tuBrunswick.title": "Technische Universität Braunschweig",
    "cv.tuBrunswick.text.1": "Abschluss: Master of Science in Psychologie",
    "cv.tuBrunswick.text.2": "Schwerpunkt: Klinische Psychologie",
    "cv.mentalExcellence.title": "Center of Mental Excellence",
    "cv.mentalExcellence.text.1": "Ausbildung im sportpsychologischem Coaching im Leistungssport",
    "cv.mentalExcellence.text.2": "Abschluss: Sportpsychologe",
    "cv.mentalExcellence.text.3": "Schwerpunktsportarten: Dressurreiten und Tennis",
    "cv.armstrongUniversity.title": "Armstrong State University (USA)",
    "cv.armstrongUniversity.text.1": "Abschluss: Bachelor of Science in Psychology",
    "cv.armstrongUniversity.text.2": "Auszeichnung: Cum Laude Tennisstipendium",
    "cv.arlingtonUniversity.title": "University of Texas at Arlington (USA)",
    "cv.arlingtonUniversity.text.1": "Studiengang: Psychologie Tennisstipendium",
    "cv.arlingtonUniversity.text.2": "Tennisstipendium",
    "cv.kgsHemmingen.title": "KGS Hemmingen – Bundesleistungszentrum Nord des Deutschen Tennis Bundes",
    "cv.kgsHemmingen.text.1": "Abschluss: Abitur",
    "cv.kgsHemmingen.text.2": "Schwerpunktfächer: Sport und Biologie",
    "cv.maximumPerformance.title": "Maximum Performance - Selbstständigkeit",
    "cv.maximumPerformance.text.1": "Gründer und Inhaber",
    "cv.awo.title": "AWO Psychiatriezentrum Königslutter",
    "cv.awo.text.1": "Stationspsychologe auf einer geschlossenen akutpsychiatrischen Station",
    "cv.bvn.title": "Bundesverband der Vertragspsychotherapeuten Niedersachsen",
    "cv.bvn.text.1": "Kooptiertes Vorstandsmitglied",
    "cv.pemi.title": "PEMI Tennis Academy",
    "cv.pemi.text.1": "Mitgeschäftsführer",
    "cv.ehrenbergKollegen.title": "Psychotherapiepraxis Ehrenberg & Kollegen",
    "cv.ehrenbergKollegen.text.2.1": "Abteilungsleiter Sportpsychologie",
    "cv.ehrenbergKollegen.text.1.1": "Freiwilliges Praktikum",
    "cv.armstrongUniversityPass.title": "Armstrong State University - PASS Lab",
    "cv.armstrongUniversityPass.text.1": "Forschungsassistent mit den Forschungsschwerpunkten: Psychologisches Momentum im Sport und Agressionsantizipation im Alltag",
    "cv.armstrongUniversityTennis.title": "Armstrong State University - Tennis Teams",
    "cv.armstrongUniversityTennis.text.1": "Student-Assistenztrainer",
    "cv.professionalSports.title": "Professioneller sportlicher Werdegang",
    "cv.professionalSports.text.1": "ITF und ATP",
    "cv.tennisAchievements.atp.primary": "Nr. 888",
    "cv.tennisAchievements.atp.secondary": "ATP - Doppelweltrangliste",
    "cv.tennisAchievements.itf.primary": "Nr. 1564",
    "cv.tennisAchievements.itf.secondary": "ITF - Junior Weltrangliste",
    "cv.tennisAchievements.usChampionships.primary": "2x 3. Platz",
    "cv.tennisAchievements.usChampionships.secondary": "USA - Meisterschaften",
    "cv.tennisAchievements.usSouthEastChampionships.primary": "4x Sieger",
    "cv.tennisAchievements.usSouthEastChampionships.secondary": "Süd - Ost USA Meisterschaften",
    "cv.tennisAchievements.secondHighestCzech.primary": "Zweithöchste tschechische Spielklasse",
    "cv.tennisAchievements.secondHighestCzech.secondary": "Wettkampferfahrung",
    "cv.tennisAchievements.secondHighestGerman.primary": "Zweithöchste deutsche Spielklasse (Wintersaison)",
    "cv.tennisAchievements.secondHighestGerman.secondary": "Wettkampferfahrung",
    "cv.hockeyAchievements.germany.primary": "Nominierung U-15 Nationalmannschaft",
    "cv.hockeyAchievements.germany.secondary": "Deutschland",
    "cv.hockeyAchievements.northGerman.primary": "5x Sieger",
    "cv.hockeyAchievements.northGerman.secondary": "Norddeutsche Meisterschaft im Nachwuchsbereich",
    "cv.hockeyAchievements.studentNational.primary": "Schüler - Bundesliga",
    "cv.hockeyAchievements.studentNational.secondary": "Wettkampferfahrung"
}
export default deCV;