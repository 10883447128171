import React from "react";
import CoachingOfferings from "../../../config/CoachingOfferings";
import SubpageWrapper from "../../../commons/subpageWrapper/SubpageWrapper";
import {useTranslation} from "react-i18next";
import OffersStyles from "../OffersStyles";
import {Typography} from "@material-ui/core";

function OtherCoaching() {


    const allOfferings = CoachingOfferings();
    const offering = allOfferings.find((entity) => entity.id === "otherCoaching")
    const {t} = useTranslation();
    const offersStyles = OffersStyles();

    return (
        <SubpageWrapper title={offering.title} backgroundImage={offering.image}
                        backgroundPosition={offering.imagePosition}>
            <Typography variant={"body1"} gutterBottom>{t("coaching.offer.otherCoaching.introduction")}</Typography>
            <Typography variant={"h3"}
                        className={offersStyles.heading}
                        gutterBottom>{t("coaching.offer.otherCoaching.lifeCounseling.title")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.otherCoaching.lifeCounseling.description")}</Typography>

            <Typography variant={"h3"}
                        className={offersStyles.heading}
                        gutterBottom>{t("coaching.offer.otherCoaching.coupleCounseling.title")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.otherCoaching.coupleCounseling.description")}</Typography>
        </SubpageWrapper>
    )


}

export default OtherCoaching;