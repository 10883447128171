import {makeStyles} from "@material-ui/core";

const subpageWrapperStyles = makeStyles(theme => ({
    titleBar: props => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        height: "40vh",
        background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${props.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: props.backgroundPosition,
        backgroundRepeat: "no-repeat",
        color: '#fff',
        marginBottom: theme.spacing(5)
    }),
    container: {
        marginBottom: theme.spacing(5)
    },
    root: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    }
}))

export default subpageWrapperStyles;