import React from "react";
import FooterStyles from "./FooterStyles";
import {Container, Tooltip, Typography} from "@material-ui/core";
import ptaWhite from "../../assets/img/pta_white.png";
import mp_simple from "../../assets/img/mp_simple.png";
import CedSoftMain from "../../assets/img/cedsoftTextWhite.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Footer() {

    const footerStyles = FooterStyles();
    const {t} = useTranslation();
    const partner = [
        {
            name: "Pemitennis Academy",
            logo: ptaWhite,
            link: "https://www.pemitennis.cz/"
        }, {
            name: "CedSoft Development",
            logo: CedSoftMain,
            link: "https://www.cedsoft.de/"
        },
    ]

    function renderPartner() {
        return partner.map(partnerEntity => (
            <a href={partnerEntity.link} target={"_blank"} rel={"noreferrer noopener"} key={partnerEntity.name}
               className={footerStyles.partnerLink}>
                <Tooltip title={partnerEntity.name} placement="top">
                    <img src={partnerEntity.logo} className={footerStyles.partnerLogo}
                         alt={`Logo of ${partnerEntity.name}`}/>
                </Tooltip>
            </a>
        ))
    }

    return (
        <div>
            <div className={footerStyles.fullCard}>
                <Container className={footerStyles.container} maxWidth={false}>
                    <Typography variant={"h5"} align={"left"}>{t("footer.partner")}</Typography>

                    <div className={footerStyles.partnerWrapper}>
                        {renderPartner()}
                    </div>

                </Container>
            </div>
            <div className={footerStyles.secondaryRoot}>
                <Container maxWidth={false} className={footerStyles.secondaryContainer}>
                    <img src={mp_simple} className={footerStyles.logo} style={{height: "3rem", width: "auto"}}
                         alt={"Logo of Maximum Performance"}/>
                    <div className={footerStyles.legal}>
                        <Link to={"/dataProtection"} className={footerStyles.legalItem}>
                            <Typography variant={"body1"}>{t("footer.dataProtection")}</Typography>
                        </Link>
                        <Typography variant={"body1"} className={footerStyles.legalItem}>|</Typography>
                        <Link to={"/imprint"} className={footerStyles.legalItem}>
                            <Typography variant={"body1"}>{t("footer.imprint")}</Typography>
                        </Link>
                    </div>
                </Container>

            </div>
        </div>
    )

}

export default Footer;