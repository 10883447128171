import React from "react";
import ProfileStyles from "./ProfileStyles";
import {Button, Container, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import socialMedias from "../../../../config/socialMedia";
import {useTranslation} from "react-i18next";
import {KeyboardArrowRight} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";

function Profile() {

    const profileStyles = ProfileStyles();
    const {t} = useTranslation();

    return (
        <div className={profileStyles.fullCard}>
            <Container className={profileStyles.container} maxWidth={false}>
                <Grid container spacing={4} className={profileStyles.mainGrid}>

                    <Grid item sm={6} className={profileStyles.flexContainer}>
                        <Typography variant={"h2"}>{t("profile.title")}</Typography>
                        <div>
                            <Typography variant={"body1"} gutterBottom>
                                {t("profile.text")}
                            </Typography>
                            <Typography variant={"body1"} gutterBottom>
                                {t("profile.text.2")}
                            </Typography>
                        </div>
                        <div>
                            <List dense>
                                {
                                    socialMedias.map((socialMedia, index) => {
                                        return (
                                            <ListItem key={index} button component={Link} href={socialMedia.link}
                                                      target={"_blank"} rel={"noreferrer"} color={"inherit"}>
                                                <ListItemIcon
                                                    className={profileStyles.icon}>{socialMedia.icon}</ListItemIcon>
                                                <ListItemText primary={socialMedia.text}/>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>

                        </div>
                    </Grid>
                    <Grid item sm={6} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                    }}>
                        <Button variant={"contained"} color={"primary"} size={"large"} endIcon={<KeyboardArrowRight/>}
                                component={RouterLink} to={"/cv"}>{t("profile.toProfile")}</Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )

}

export default Profile;