import React from "react";
import Navigation from "../../commons/navigation/Navigation";
import Footer from "../../commons/footer/Footer";
import OpenerImage from "./sections/openerImage/OpenerImage";
import Welcome from "./sections/welcome/Welcome";
import Profile from "./sections/profile/Profile";
import Coaching from "./sections/coaching/Coaching";
import Contact from "./sections/contact/Contact";

function Main(props) {

    return (
        <>
            <Navigation window={props.window} disableBottomPadding={true}/>
            <div id={"start"}>
                <OpenerImage/>
                <Welcome/>
            </div>
            <div id={"coaching"}>
                <Coaching/>
            </div>
            <div id={"profile"}>
                <Profile/>
            </div>
            <div id={"contact"}>
                <Contact/>
            </div>
            <Footer/>
        </>
    )
}

export default Main;