import {makeStyles} from "@material-ui/core";

const NavigationStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        height: "6rem",
        width: "auto"
    },
    spaceFill: {
        flexGrow: 1
    },
    appBar: {
        height: "6rem"
    }
}));

export default NavigationStyles;