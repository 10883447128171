import {makeStyles} from "@material-ui/core";

const offerStyles = makeStyles(theme => ({

    heading: {
        marginTop: theme.spacing(5)
    },
    smallHeading: {
        marginTop: theme.spacing(3)
    },
    spotlightGrid: {
        textAlign: "center",
        marginTop: theme.spacing(3)
    },
    spotlightIcon: {
        fontSize: "6rem"
    },
    spotlightWrapper: {
        padding: theme.spacing(4)
    },
    spotlightCard: {
        padding: "3rem"
    },
    accordionWrapper: {
        marginTop: theme.spacing(3)
    }
}))

export default offerStyles