const czCv = {
    "cv.tuDresden.title": "Technische Universität Dresden",
    "cv.tuBrunswick.text.2": "Zaměření: klinická psychologie",
    "cv.arlingtonUniversity.text.1": "Studium: Psychologie tenisové stipendium",
    "cv.ehrenbergKollegen.title": "Psychotherapiepraxis Ehrenberg & Kollegen",
    "cv.title": "Životopis",
    "cv.today": "dnes",
    "cv.tuDresden.text.1": "Doktorské studium na Lékařské fakultě Carla Gustava Caruse.",
    "cv.tuDresden.text.2": "Usilovací titul: Dr. rer. medic.",
    "cv.dinklar.title": "Gesellschaft für Verhaltenstherapie Dinklar",
    "cv.diklar.text.1": "Psychologický psychoterapeut ve výcviku",
    "cv.tuBrunswick.title": "Technische Universität Braunschweig",
    "cv.tuBrunswick.text.1": "Titul: Master of Science v psychologii",
    "cv.mentalExcellence.title": "Center of Mental Excellence",
    "cv.mentalExcellence.text.1": "Výcvik sportovního psychologického coachingu v závodním sportu",
    "cv.mentalExcellence.text.3": "Hlavní sporty: drezura a tenis",
    "cv.armstrongUniversity.text.1": "Titul: Bachelor of Science in Psychology",
    "cv.armstrongUniversity.text.2": "Vyznamenání: Cum Laude tenisové stipendium",
    "cv.arlingtonUniversity.title": "University of Texas at Arlington (USA)",
    "cv.kgsHemmingen.title": "KGS Hemmingen - Národní tenisové centrum Německého tenisového svazu",
    "cv.kgsHemmingen.text.1": "Titul: Maturita",
    "cv.kgsHemmingen.text.2": "Hlavní předměty: sport a biologie",
    "cv.maximumPerformance.title": "Maximum Performance - Nezávislý",
    "cv.maximumPerformance.text.1": "Zakladatel a majitel",
    "cv.awo.title": "AWO Psychiatriezentrum Königslutter",
    "cv.bvn.title": "Bundesverband der Vertragspsychotherapeuten Niedersachsen",
    "cv.bvn.text.1": "Kooptovaný člen představenstva",
    "cv.pemi.title": "PEMI Tennis Academy",
    "cv.pemi.text.1": "Společný výkonný ředitel",
    "cv.ehrenbergKollegen.text.2.1": "Vedoucí sportovní psychologie",
    "cv.ehrenbergKollegen.text.1.1": "Dobrovolná stáž",
    "cv.armstrongUniversityPass.title": "Armstrong State University - PASS Lab",
    "cv.armstrongUniversityTennis.title": "Armstrong State University - tenisové týmy",
    "cv.armstrongUniversityTennis.text.1": "Studentský asistent trenéra",
    "cv.professionalSports.title": "Profesionální sportovní kariéra",
    "cv.professionalSports.text.1": "ITF a ATP",
    "cv.tuDresden.text.3": "Výzkum sebevražd v profesionálním fotbalu",
    "cv.armstrongUniversity.title": "Armstrong State University (USA)",
    "cv.awo.text.1": "Psycholog na uzavřeném akutním psychiatrickém oddělení",
    "cv.armstrongUniversityPass.text.1": "Odborný asistent se specializací na: psychologické momentum ve sportu a anticipace agresivního chování v každodenním životě",
    "cv.tennisAchievements.itf.primary": "č. 1564",
    "cv.tennisAchievements.itf.secondary": "Juniorský světový žebříček ITF",
    "cv.tennisAchievements.usChampionships.primary": "2x 3. místo",
    "cv.tennisAchievements.usChampionships.secondary": "Mistrovství USA",
    "cv.tennisAchievements.usSouthEastChampionships.primary": "4x 1. místo",
    "cv.tennisAchievements.usSouthEastChampionships.secondary": "Mistrovství jihovýchodní USA",
    "cv.tennisAchievements.secondHighestCzech.secondary": "Soutěžní zkušenost",
    "cv.tennisAchievements.secondHighestGerman.primary": "2. nejvyšší německá liga (zimní sezóna)",
    "cv.tennisAchievements.secondHighestGerman.secondary": "Soutěžní zkušenost",
    "cv.hockeyAchievements.germany.primary": "Nominace národní tým do 15ti let",
    "cv.hockeyAchievements.germany.secondary": "Německo",
    "cv.hockeyAchievements.northGerman.primary": "5x 1. místo",
    "cv.hockeyAchievements.northGerman.secondary": "Severoněmecké mistrovství v oblasti mládeže a dorostu",
    "cv.hockeyAchievements.studentNational.primary": "Dorostenecká Bundesliga",
    "cv.hockeyAchievements.studentNational.secondary": "Soutěžní zkušenost",
    "cv.mentalExcellence.text.2": "Titul: sportovní psycholog",
    "cv.tennisAchievements.atp.primary": "č. 888",
    "cv.tennisAchievements.atp.secondary": "Deblový světový žebříček ATP",
    "cv.tennisAchievements.secondHighestCzech.primary": "1. národní liga"
}

export default czCv;