import {makeStyles} from "@material-ui/core";

const ContactStyles = makeStyles(theme => ({
    fullCard: {
        minHeight: '70vh',
        background: theme.rootCard.main.background,
        color: theme.rootCard.main.color,
        display: "flex"
    },
    container: {
        paddingTop: theme.rootCard.padding.top,
        paddingBottom: theme.rootCard.padding.bottom
    },
    mainGrid: {
        marginTop: theme.spacing(5),
        width: "100%"
    },
    formElement: {
        width: "100%"
    }
}))

export default ContactStyles;