import {createMuiTheme, responsiveFontSizes} from "@material-ui/core";

const MpTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#233e5a",
            contrastText: "#EEEEEE",

        },
        secondary: {
            main: "#eee",
            contrastText: "#757575"
        },
    },
    typography: {
        fontFamily: ["Raleway", "Roboto", "Helvetica", "Arial", "sans-serif"]
    },
    rootCard: {
        padding: {
            top: "3rem",
            bottom: "2rem",
        },
        main: {
            background: "#EEEEEE",
            color: "#757575"
        },
        contrast: {
            background: "linear-gradient(137deg, rgba(52,121,191,1) 0%, rgba(43,89,135,1) 100%)",
            color: "#eeeeee",

        }
    }
})

export default responsiveFontSizes(MpTheme);