const enCoachingSportPsychology = {
    "coaching.offer.sportPsychology.title": "Sports psychology",
    "coaching.offer.sportPsychology.introduction": "Sports psychology is a scientific field at the interface of psychology, sports science and medicine. Its contents are the research of the psychological fundamentals, the processes in sport and the effects of sport in order to derive scientifically based training measures to optimize behavior in sports (Beckmann & Elbe, 2011). ",
    "coaching.offer.sportPsychology.myOfferings.introduction.1": "All offered services are aimed at all performance-oriented athletes. There is no minimum age for sports psychology coaching.",
    "coaching.offer.sportPsychology.myOfferings.introduction.2": "My services deal with the following spectrum of sports psychology:",
    "coaching.offer.sportPsychology.myOfferings.sportPsychologySingleCoaching.title": "Individual coaching",
    "coaching.offer.sportPsychology.myOfferings.workshops.description": "I offer the following subject areas: goal setting training, relaxation training, crisis interventions, concentration training, dealing with pressure, imagination training, environment management, mindfulness training, identification training. Further subject areas can also be worked out by an arrangement.",
    "coaching.offer.sportPsychology.myOfferings.sportPsychologySingleCoaching.description": "This is about the face-to-face interaction, in which the client works on the previously set goals. Both sport-specific movement exercises (e.g. a forehand or a free kick technique) and mental techniques can be trained.",
    "coaching.offer.sportPsychology.myOfferings.dataAnalytics.title": "Data analysis and sports psychological diagnostics",
    "coaching.offer.sportPsychology.myOfferings.dataAnalytics.description": "If a certain data set is complete, a statistical evaluation of this datasheet can take place. The data available here are evaluated using mathematical processes and the result is then made available for you. This can involve the evaluation of sport-related data such as the success calculation when changing the team line-up as well as the evaluation of self-made sport psychological diagnostic evaluation procedures.",
    "coaching.offer.sportPsychology.myOfferings.trainingCare.title": "Coaching during practice, training camp and at comepetition",
    "coaching.offer.sportPsychology.myOfferings.trainingCare.description": "In this coaching type, the person receiving advice is observed during training, camp, competition or a similar event, analyzed in terms of sports psychology and the mental performance of the respective person is evaluated and discussed. After such support, the athlete receives a performance report covering the entire time of the support.",
    "coaching.offer.sportPsychology.myOfferings.workshops.title": "Sports psychological workshops and lectures",
    "coaching.offer.sportPsychology.myOfferings.teambuilding.title": "Teambuilding",
    "coaching.offer.sportPsychology.myOfferings.teambuilding.description": "Teambuilding is about promoting the teams chemistry in order to be able to use it successfully in competition. Teambuilding can take place in the club's internal rooms or, by an arrangement, can also be held externally.",
    "coaching.offer.sportPsychology.myOfferings.coachTheCoach.title": "Coach the Coach",
    "coaching.offer.sportPsychology.myOfferings.coachTheCoach.description": "Mainly the psychosocial competencies of a coach are looked after and optimized. The general goal of this offer is to maximize the performance of the team or an individual by strengthening the skills of the coach.",
    "coaching.offer.sportPsychology.myOfferings.parentalCoaching.title": "Coach the parents",
    "coaching.offer.sportPsychology.cooperation.description.1": "A sports psychology session takes usually 50 minutes, but can be longer if agreed. Offers such as training / training camp / competition coaching, which are outside the agreed coaching room, can also take place all day or over several days.The number of sessions, can be selected individually according to the goals and expectations of the athlete.",
    "coaching.offer.sportPsychology.cooperation.description.2": "I offer rooms for sports psychological care, but it is also possible to meet in other rooms, as long as the safety framework for such care is guaranteed.",
    "coaching.offer.sportPsychology.myOfferings.parentalCoaching.description": "Supporting one's own child's sports career is an important factor in unleashing the potential of the athlete. Parental coaching creates the basis for being able to deal with pressure situations or being able to work efficiently for one's own child.",
    "coaching.offer.sportPsychology.procedure.description": "At the beginning of each coaching, the athlete's goals and expectations are discussed and agreed upon. Here, the formalities around the coaching are explained and a concept is drawn up together. In the following sessions, the progress of the athlete is checked at regular intervals using documentation and sport-psychological test diagnostics in order to work efficiently on the previously set goals over the entire period."
}
export default enCoachingSportPsychology;