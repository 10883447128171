import React from "react";
import SubpageWrapper from "../../../commons/subpageWrapper/SubpageWrapper";
import CoachingOfferings from "../../../config/CoachingOfferings";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import OffersStyles from "../OffersStyles";
import MultipleAccordions from "../../../commons/multipleAccordions/MultipleAccordions";

function BusinessCoaching() {

    const allOfferings = CoachingOfferings();
    const offering = allOfferings.find((entity) => entity.id === "businessCoaching")
    const {t} = useTranslation();
    const offersStyles = OffersStyles();

    const accordionContent = [
        {
            title: t("coaching.offer.businessCoaching.myOfferings.businessSingleCoaching.title"),
            key: "businessSingleCoaching",
            descriptions: [t("coaching.offer.businessCoaching.myOfferings.businessSingleCoaching.description")]
        },
        {
            title: t("coaching.offer.businessCoaching.myOfferings.executiveCoaching.title"),
            key: "executiveCoaching",
            descriptions: [t("coaching.offer.businessCoaching.myOfferings.executiveCoaching.description")]
        },
        {
            title: t("coaching.offer.businessCoaching.myOfferings.businessWorkshop.title"),
            key: "businessWorkshop",
            descriptions: [
                t("coaching.offer.businessCoaching.myOfferings.businessWorkshop.description.1"),
                t("coaching.offer.businessCoaching.myOfferings.businessWorkshop.description.2")
            ]
        },
        {
            title: t("coaching.offer.businessCoaching.myOfferings.teamCare.title"),
            key: "teamCare",
            descriptions: [t("coaching.offer.businessCoaching.myOfferings.teamCare.description")]
        },
        {
            title: t("coaching.offer.businessCoaching.myOfferings.teambuilding.title"),
            key: "teambuilding",
            descriptions: [t("coaching.offer.businessCoaching.myOfferings.teambuilding.description")]
        }
    ]

    return (
        <SubpageWrapper title={offering.title} backgroundImage={offering.image}
                        backgroundPosition={offering.imagePosition}>
            <Typography variant={"body1"} gutterBottom>{t("coaching.offer.businessCoaching.introduction")}</Typography>

            <Typography variant={"h3"} gutterBottom
                        className={offersStyles.heading}>{t("coaching.myOffers")}</Typography>

            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.businessCoaching.myOfferings.introduction.1")}</Typography>

            <div className={offersStyles.accordionWrapper}>
                <MultipleAccordions content={accordionContent}/>

            </div>

            <Typography variant={"h3"} gutterBottom
                        className={offersStyles.heading}>{t("coaching.cooperation")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.businessCoaching.cooperation.description.1")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.businessCoaching.cooperation.description.2")}</Typography>

            <Typography variant={"h3"} gutterBottom
                        className={offersStyles.heading}>{t("coaching.procedure")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.businessCoaching.procedure.description")}</Typography>

        </SubpageWrapper>
    )

}

export default BusinessCoaching;