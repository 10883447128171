import React from "react";
import {Card, CardActionArea, Typography} from "@material-ui/core";
import CoachingCardStyles from "./CoachingCardStyles";
import {Link} from "react-router-dom";

function CoachingCard(props) {

    const coachingCardStyles = CoachingCardStyles({
        backgroundImage: props.image,
        backgroundPosition: props.imagePosition
    });

    return (
        <>
            <Card elevation={5} className={coachingCardStyles.root}>
                <CardActionArea centerRipple={true} className={coachingCardStyles.action} component={Link}
                                to={props.address}>
                    <Typography gutterBottom variant={"h5"} component={"h2"}
                                style={{hyphens: "auto"}}>{props.title}</Typography>
                </CardActionArea>
            </Card>
        </>
    )
}

export default CoachingCard;