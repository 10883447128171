import {makeStyles} from "@material-ui/core";

const CVStyles = makeStyles(theme => ({

    paper: {
        padding: theme.spacing(3)
    },
    missingOppositeContent: {
        "&:before": {
            display: "none"
        }
    },
    text: {
        hyphens: "auto"
    },
    achievementPaper: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(2)
    },
    achievementIcon: {
        fontSize: "4rem",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }

}))
export default CVStyles