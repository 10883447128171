const czContact = {
    "contact.surname": "Příjmení",
    "contact.email": "E-Mail",
    "contact.forename": "Jméno",
    "contact.telephone": "Telefonní číslo",
    "contact.message": "Sdělení",
    "contact.title": "Kontakt",
    "contact.pandemicInfo": "Poradenská sezení mohou probíhat ve vlastních klubových, ev. firemních prostorách. Jestliže tyto prostory nejsou k dispozici, sezení se mohou uskutečnit u partnerů, se kterými v regionu Braunschweig – Wolfsburg spolupracujeme. Vzhledem k současné koronavirové pandemii může dojít k určtým omezením při zadávání termínů. Po domluvě může rozhovor probíhat i formou telefonického nebo video hovoru."

}
export default czContact;