import React from "react";
import ScrollToTop from "../../commons/scrollToTop/ScrollToTop";
import SubpageWrapper from "../../commons/subpageWrapper/SubpageWrapper";
import Marek from "../../assets/img/profile.jpg";
import {useTranslation} from "react-i18next";
import {Grid, List, ListItem, ListItemText, Paper, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator
} from "@material-ui/lab";
import {School, SportsHockey, SportsTennis, Work} from "@material-ui/icons";
import CVStyles from "./CVStyles";


function CV() {

    const {t} = useTranslation();
    const cvStyles = CVStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const cvSteps = [
        {
            from: "2020-10",
            to: "today",
            title: t("cv.tuDresden.title"),
            texts: [t("cv.tuDresden.text.1"), t("cv.tuDresden.text.2"), t("cv.tuDresden.text.3")],
            icon: <School/>
        },
        {
            from: "2020-04",
            to: "today",
            title: t("cv.dinklar.title"),
            texts: [t("cv.diklar.text.1")],
            icon: <School/>
        },
        {
            from: "2017-10",
            to: "2019-12",
            title: t("cv.tuBrunswick.title"),
            texts: [t("cv.tuBrunswick.text.1"), t("cv.tuBrunswick.text.2")],
            icon: <School/>
        },
        {
            from: "2018-05",
            to: "2019-02",
            title: t("cv.mentalExcellence.title"),
            texts: [t("cv.mentalExcellence.text.1"), t("cv.mentalExcellence.text.2"), t("cv.mentalExcellence.text.3")],
            icon: <School/>
        },
        {
            from: "2014-08",
            to: "2016-12",
            title: t("cv.armstrongUniversity.title"),
            texts: [t("cv.armstrongUniversity.text.1"), t("cv.armstrongUniversity.text.2"), t("cv.armstrongUniversity.text.3")],
            icon: <School/>
        },
        {
            from: "2013-01",
            to: "2014-05",
            title: t("cv.arlingtonUniversity.title"),
            texts: [t("cv.arlingtonUniversity.text.1"), t("cv.arlingtonUniversity.text.2")],
            icon: <School/>
        },
        {
            from: "2011-07",
            to: "2012-06",
            title: t("cv.kgsHemmingen.title"),
            texts: [t("cv.kgsHemmingen.text.1"), t("cv.kgsHemmingen.text.2")],
            icon: <School/>
        },
        {
            from: "2021-01",
            to: "today",
            title: t("cv.maximumPerformance.title"),
            texts: [t("cv.maximumPerformance.text.1")],
            icon: <Work/>
        },
        {
            from: "2020-04",
            to: "today",
            date: "April 2020 – heute",
            title: t("cv.awo.title"),
            texts: [t("cv.awo.text.1")],
            icon: <Work/>
        },
        {
            from: "2019-02",
            to: "today",
            title: t("cv.bvn.title"),
            texts: [t("cv.bvn.text.1")],
            icon: <Work/>
        },
        {
            from: "2012-01",
            to: "today",
            title: t("cv.pemi.title"),
            texts: [t("cv.pemi.text.1")],
            icon: <Work/>
        },
        {
            from: "2017-10",
            to: "2020-02",
            title: t("cv.ehrenbergKollegen.title"),
            texts: [t("cv.ehrenbergKollegen.text.2.1")],
            icon: <Work/>
        },
        {
            from: "2017-01",
            to: "2017-06",
            title: t("cv.ehrenbergKollegen.title"),
            texts: [t("cv.ehrenbergKollegen.text.1.1")],
            icon: <Work/>
        },
        {
            from: "2016-01",
            to: "2016-12",
            title: t("cv.armstrongUniversityPass.title"),
            texts: [t("cv.armstrongUniversityPass.text.1")],
            icon: <Work/>
        },
        {
            from: "2016-06",
            to: "2016-12",
            title: t("cv.armstrongUniversityTennis.title"),
            texts: [t("cv.armstrongUniversityTennis.text.1")],
            icon: <Work/>
        },
        {
            from: "2012-07",
            to: "2012-12",
            title: t("cv.professionalSports.title"),
            texts: [t("cv.professionalSports.text.1")],
            icon: <Work/>
        }
    ];

    const tennisAchievements = [
        {
            primary: t("cv.tennisAchievements.atp.primary"),
            secondary: t("cv.tennisAchievements.atp.secondary")
        },
        {
            primary: t("cv.tennisAchievements.itf.primary"),
            secondary: t("cv.tennisAchievements.itf.secondary")
        },
        {
            primary: t("cv.tennisAchievements.usChampionships.primary"),
            secondary: t("cv.tennisAchievements.usChampionships.secondary")
        },
        {
            primary: t("cv.tennisAchievements.usSouthEastChampionships.primary"),
            secondary: t("cv.tennisAchievements.usSouthEastChampionships.secondary")
        },
        {
            primary: t("cv.tennisAchievements.secondHighestCzech.primary"),
            secondary: t("cv.tennisAchievements.secondHighestCzech.secondary")
        },
        {
            primary: t("cv.tennisAchievements.secondHighestGerman.primary"),
            secondary: t("cv.tennisAchievements.secondHighestGerman.secondary")
        }
    ];

    const hockeyAchievements = [
        {
            primary: t("cv.hockeyAchievements.germany.primary"),
            secondary: t("cv.hockeyAchievements.germany.primary")
        },
        {
            primary: t("cv.hockeyAchievements.northGerman.primary"),
            secondary: t("cv.hockeyAchievements.northGerman.secondary")
        },
        {
            primary: t("cv.hockeyAchievements.studentNational.primary"),
            secondary: t("cv.hockeyAchievements.studentNational.secondary")
        }
    ];

    cvSteps.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.from) - new Date(a.from);
    });

    function generateFrom(from) {
        const fromDate = new Date(from);
        return fillZeros(fromDate.getMonth() + 1) + " " + fromDate.getFullYear();
    }

    function generateTo(to) {
        if (to === "today") {
            return t("cv.today")
        }
        const toDate = new Date(to);

        return fillZeros(toDate.getMonth() + 1) + " " + toDate.getFullYear();
    }

    function renderSeparator(current, length) {
        if (current !== length) {
            return <TimelineConnector/>;
        }
    }

    function renderTimelineAlign() {

        const alignObject = {align: "alternate"};
        if (isMobile) {
            alignObject.align = "left";
        }
        return alignObject
    }

    function renderTimelineItemClass() {
        if (isMobile) {
            return {className: cvStyles.missingOppositeContent}
        }
    }

    function fillZeros(number) {
        if (number < 10) {
            return "0" + number
        }
        return number
    }

    return (
        <ScrollToTop>
            <SubpageWrapper title={t("cv.title")} backgroundImage={Marek} backgroundPosition={"100% 35%"}>
                <Timeline {...renderTimelineAlign()}>

                    {cvSteps.map((cvStep, index) => {
                        return (
                            <TimelineItem key={index} {...renderTimelineItemClass()}>
                                <TimelineSeparator>
                                    <TimelineDot color={"primary"}>
                                        {cvStep.icon}
                                    </TimelineDot>
                                    {renderSeparator((index + 1), cvSteps.length)}
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={cvStyles.paper}>
                                        <Typography variant="h6" component="h1" align={"left"}
                                                    className={cvStyles.text}>
                                            {cvStep.title}
                                        </Typography>
                                        <Typography variant={"subtitle1"} gutterBottom align={"left"}
                                                    className={cvStyles.text}>
                                            {generateFrom(cvStep.from) + " - " + generateTo(cvStep.to)}
                                        </Typography>
                                        {cvStep.texts.map(text => (
                                            <Typography gutterBottom align={"left"}
                                                        className={cvStyles.text}>{text}</Typography>
                                        ))}
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })}
                </Timeline>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <Paper elevation={5} className={cvStyles.achievementPaper}>
                            <div style={{textAlign: "center"}}>
                                <SportsTennis fontSize={"large"} color={"primary"}
                                              className={cvStyles.achievementIcon}/>
                            </div>
                            <List>
                                {
                                    tennisAchievements.map(achievement => (
                                        <ListItem>
                                            <ListItemText
                                                primary={achievement.primary}
                                                secondary={achievement.secondary}
                                            />
                                        </ListItem>
                                    ))
                                }

                            </List>
                        </Paper>
                    </Grid>
                    <Grid item sm={6}>
                        <Paper elevation={5} className={cvStyles.achievementPaper}>
                            <div style={{textAlign: "center"}}>
                                <SportsHockey fontSize={"large"} color={"primary"}
                                              className={cvStyles.achievementIcon}/>
                            </div>
                            <List>
                                {
                                    hockeyAchievements.map(achievement => (
                                        <ListItem>
                                            <ListItemText
                                                primary={achievement.primary}
                                                secondary={achievement.secondary}
                                            />
                                        </ListItem>
                                    ))
                                }

                            </List>
                        </Paper>

                    </Grid>

                </Grid>

            </SubpageWrapper>
        </ScrollToTop>
    );

}

export default CV;