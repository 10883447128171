import React from "react";
import {Route} from "react-router-dom";
import BusinessCoaching from "./businessCoaching/BusinessCoaching";
import MotivationalSpeaking from "./motivationalSpeaking/MotivationalSpeaking";
import OtherCoaching from "./otherCoaching/OtherCoaching";
import SportPsychology from "./sportPsychology/SportPsychology";
import ScrollToTop from "../../commons/scrollToTop/ScrollToTop";

function OffersRouter() {

    return (
        <ScrollToTop>
            <Route path={"/offers/businessCoaching"} component={BusinessCoaching}/>
            <Route path={"/offers/motivationalSpeaking"} component={MotivationalSpeaking}/>
            <Route path={"/offers/otherCoaching"} component={OtherCoaching}/>
            <Route path={"/offers/sportPsychology"} component={SportPsychology}/>
        </ScrollToTop>
    )

}

export default OffersRouter