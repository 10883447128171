import React, {useEffect, useState} from "react";
import {ThemeProvider} from '@material-ui/core/styles'
import MpTheme from "./assets/theme/MpTheme";
import {BrowserRouter as Router} from "react-router-dom"
import MainRouter from "./pages/MainRouter";
import "./translation/translation"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {Button, Snackbar} from "@material-ui/core";
import {useTranslation} from "react-i18next";


function App() {

    const [newVersionAvailable, setNewVersionAvailable] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState({});
    const {t} = useTranslation();

    function onServiceWorkerUpdate(registration) {
        setNewVersionAvailable(true);
        setWaitingWorker(registration.waiting);
    }

    function updateServiceWorker() {
        waitingWorker.postMessage({type: "SKIP_WAITING"});
        setNewVersionAvailable(false);
        window.location.reload(true);
    }

    useEffect(() => {
        serviceWorkerRegistration.register({onUpdate: onServiceWorkerUpdate});
    }, [])

    function handleUpdateClose() {
        setNewVersionAvailable(false);
    }

    function renderSnackbar() {
        return (
            <Snackbar
                color={"primary"}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={newVersionAvailable}
                onClose={handleUpdateClose}
                message={t("pwa.newVersionAvailable")}
                key={"new-version-available"}
                action={<Button size={"small"} color={"secondary"}
                                onClick={updateServiceWorker}>{t("pwa.refresh")}</Button>}
            />
        )
    }

    return (
        <Router>
            <ThemeProvider theme={MpTheme}>
                {renderSnackbar()}
                <MainRouter/>
            </ThemeProvider>
        </Router>

    );

}

export default App;
