import React from "react";
import {
    AppBar,
    Button,
    Menu,
    MenuItem,
    Slide,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
    useTheme
} from "@material-ui/core";
import mp_full from "../../assets/img/mp_full.png";
import mp_simple from "../../assets/img/mp_simple.png";
import NavigationStyles from "./NavigationStyles";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HashLink} from "react-router-hash-link"
import {Language} from "@material-ui/icons";


function HideOnScroll(props) {
    const {children, window} = props;

    const trigger = useScrollTrigger({target: window ? window() : undefined});

    return (
        <Slide appear={false} direction={"down"} in={!trigger}>
            {children}
        </Slide>
    )
}

function Navigation(props) {

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = NavigationStyles();
    const {t, i18n} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);


    function getLogo() {
        if (small) {
            return mp_simple;
        }
        return mp_full;

    }

    function renderAnchors() {
        if (small) {
            return;
        }
        return (
            <React.Fragment>
                <Button color="inherit" component={HashLink} smooth to={"/#start"}>{t("welcome.title")}</Button>
                <Button color="inherit" component={HashLink} smooth to={"/#profile"}>{t("profile.title")}</Button>
                <Button color="inherit" component={HashLink} smooth to={"/#coaching"}>{t("coaching.title")}</Button>
                <Button color="inherit" component={HashLink} smooth to={"/#contact"}>{t("contact.title")}</Button>
            </React.Fragment>
        )
    }

    function renderBottomPadding() {
        if (props.disableBottomPadding) {
            return;
        }
        return (
            <div className={classes.appBar}/>

        )
    }

    function openLanguageMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function closeLanguageMenu() {
        setAnchorEl(null);
    }

    function switchLanguageTo(language) {
        setAnchorEl(null);
        i18n.changeLanguage(language);
    }


    return (
        <>
            <HideOnScroll window={props.window}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Link to={"/"}>
                            <img src={getLogo()} className={classes.title} alt={"Logo of Maximum Performance"}/>
                        </Link>
                        <div className={classes.spaceFill}/>
                        {renderAnchors()}
                        <Button color="inherit" onClick={openLanguageMenu}><Language/></Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={closeLanguageMenu}
                        >
                            <MenuItem onClick={() => switchLanguageTo("de")}>Deutsch</MenuItem>
                            <MenuItem onClick={() => switchLanguageTo("en")}>English</MenuItem>
                            <MenuItem onClick={() => switchLanguageTo("cz")}>Česky</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            {renderBottomPadding()}
        </>
    )

}

export default Navigation