import React from "react";
import CoachingStyles from "./CoachingStyles";
import {Container, Grid, Typography} from "@material-ui/core";
import CoachingCard from "../../../../commons/coachingCard/CoachingCard";
import {useTranslation} from "react-i18next";
import CoachingOfferings from "../../../../config/CoachingOfferings";

function Coaching() {

    const coachingStyles = CoachingStyles();
    const {t} = useTranslation();


    return (
        <div className={coachingStyles.fullCard}>
            <Container className={coachingStyles.container} maxWidth={false}>
                <Typography variant={"h2"} className={coachingStyles.title}>{t("coaching.title")}</Typography>
                <Grid container spacing={5} className={coachingStyles.mainGrid} justify={"center"}
                      alignItems={"center"}>
                    {
                        CoachingOfferings().map((coaching, key) => {
                            return (
                                <Grid item sm={3} key={key} style={{width: "100%", align: "center"}}>
                                    <CoachingCard image={coaching.image} imagePosition={coaching.imagePosition}
                                                  title={coaching.title}
                                                  address={coaching.address}/>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </div>
    )
}

export default Coaching;