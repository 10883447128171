import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import en from "./en";
import de from "./de";
import cz from "./cz";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {translation: en},
            de: {translation: de},
            cz: {translation: cz}
        },
        fallbackLng: ["en", "de"],
        keySeparator: false,
        useCookies: false,
        debug: false,
        interpolation: {
            escapeValue: false
        }
    }, (err, t) => {
        if (err) {
            console.error(err)
        }
    })
export default i18n;