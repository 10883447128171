const enCoachingBusinessCoaching = {
    "coaching.offer.businessCoaching.myOfferings.teambuilding.title": "Teambuilding",
    "coaching.offer.businessCoaching.cooperation.description.1": "A business coaching session takes usually 50 minutes, but can be longer if agreed. Offers such as team building or workshops and lectures which may be held outside the coaching room can also take place all day or over several days. The number of sessions can be selected individually according to the goals and expectations of the clients.",
    "coaching.offer.businessCoaching.cooperation.description.2": "I offer rooms for business coaching, but it is also possible to meet in other rooms as long as the safety framework for such support is guaranteed.",
    "coaching.offer.businessCoaching.introduction": "Business coaching is about target-oriented advice on topics related to the professional setting. The clients are supported by means of coaching in maximizing their strengths in order to further optimize their performance in the work context. Business coaching is also very often carried out in a group context in order to help teams create maximum synergy (optimal cooperation) and to work efficiently.",
    "coaching.offer.businessCoaching.myOfferings.teamCare.description": "Team coaching is about the constant support of a team or an organization. Meetings are scheduled at certain time intervals in order to be able to evaluate the progress of this collective and to be able to openly address recurring challenges in everyday organizational life.",
    "coaching.offer.businessCoaching.myOfferings.teambuilding.description": "Teambuilding is about promoting the chemistry of an entire department (or small group) in order to be able to use it successfully in the economic field. Team building can take place in the organization's own rooms or, by an arrangement, also be held externally.",
    "coaching.offer.businessCoaching.procedure.description": "At the beginning of each coaching, the goals and expectations of the organization or of the individual are discussed and agreed upon in the initial meeting. Here, the formalities around the coaching are explained and a concept is drawn up together. In the following meetings, the progress of the team or the person requiring care is checked at regular intervals using documentation and organizational psychological test diagnostics in order to work efficiently on the previously set goals. ",
    "coaching.offer.businessCoaching.title": "Business Coaching",
    "coaching.offer.businessCoaching.myOfferings.introduction.1": "My services cover the following spectrum of business and organizational psychology:",
    "coaching.offer.businessCoaching.myOfferings.businessSingleCoaching.title": "Individual business coaching",
    "coaching.offer.businessCoaching.myOfferings.businessSingleCoaching.description": "This is about face-to-face coaching, in which the client works on the previously set goals. Both organization-specific topics and topics relating to one's own everyday life can be addressed in order to achieve an ideal work-life balance and develop an efficient work routine.",
    "coaching.offer.businessCoaching.myOfferings.executiveCoaching.title": "Executive coaching",
    "coaching.offer.businessCoaching.myOfferings.executiveCoaching.description": "This coaching is about increasing the efficiency in a company while maintaining or improving the harmony in a corresponding team. For this purpose, the areas of communication, formulation of goals and tracking, as well as motivation and development of an optimal working atmosphere in relation to existing individual leadership qualities are taken into perspective.",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.title": "Business workshops and lectures",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.description.1": "I offer my coachings in the following subject areas: team development, team analysis, rhetoric, empathic communication, work - life balance, leadership and crisis management.",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.description.2": "Further subject areas can also be worked out by arrangement.",
    "coaching.offer.businessCoaching.myOfferings.teamCare.title": "Team coaching"
}
export default enCoachingBusinessCoaching;