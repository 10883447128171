import SportPsychic from "../assets/img/sportPsychic.jpg";
import SportPsychology from "../pages/main/sections/coaching/descriptions/SportPsychology";
import BusinessCoaching from "../assets/img/businessCoaching.jpg";
import {Typography} from "@material-ui/core";
import MotivationalCoaching from "../assets/img/motivationalSpeaking.jpg";
import OtherCoaching from "../assets/img/otherCoachings.jpg";
import React from "react";
import {useTranslation} from "react-i18next";


function CoachingOfferings() {

    const {t} = useTranslation();

    return [
        {
            id: "sportPsychology",
            image: SportPsychic,
            imagePosition: "center",
            title: t("coaching.offer.sportPsychology.title"),
            description: <SportPsychology/>,
            address: "/offers/sportPsychology"
        },
        {
            id: "businessCoaching",
            image: BusinessCoaching,
            imagePosition: "center",
            title: t("coaching.offer.businessCoaching.title"),
            description: <Typography variant={"body1"}>{t("coaching.offer.businessCoaching.description")}</Typography>,
            address: "/offers/businessCoaching"

        },
        {
            id: "motivationalSpeaking",
            image: MotivationalCoaching,
            imagePosition: "bottom",
            title: t("coaching.offer.motivationalSpeaking.title"),
            description: <Typography
                variant={"body1"}>{t("coaching.offer.motivationalSpeaking.description")}</Typography>,
            address: "/offers/motivationalSpeaking"

        },
        {
            id: "otherCoaching",
            image: OtherCoaching,
            imagePosition: "center",
            title: t("coaching.offer.otherCoaching.title"),
            description: <Typography variant={"body1"}>{t("coaching.offer.otherCoaching.description")}</Typography>,
            address: "/offers/otherCoaching"

        }
    ]
}

export default CoachingOfferings;