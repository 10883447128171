import {makeStyles} from "@material-ui/core";

const DataProtectionStyles = makeStyles(theme => ({

    heading: {
        marginTop: theme.spacing(5)
    },
    smallHeading: {
        marginTop: theme.spacing(3)
    },
    miniHeading: {
        marginTop: theme.spacing(2)
    },

}))
export default DataProtectionStyles;