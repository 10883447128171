import {makeStyles} from "@material-ui/core";
import Profile from "../../../../assets/img/profile.jpg";
import StadiumImage from "../../../../assets/img/profileStadium.jpg";

const profileStyles = makeStyles(theme => ({
    fullCard: {
        minHeight: '90vh',
        height: "auto",
        background: theme.rootCard.contrast.background,
        color: theme.rootCard.contrast.color,
        [theme.breakpoints.up('sm')]: {
            backgroundImage: `linear-gradient(to right, rgba(0,0,0,.75), rgba(0,0,0,.6), rgba(0,0,0,0)),url(${StadiumImage})`,
        },
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `linear-gradient(to right, rgba(0,0,0,.75), rgba(0,0,0,.75)),url(${StadiumImage})`,
        },
        backgroundPosition: "right",
        backgroundSize: "cover",
        display: "flex",
    },
    imagePaper: {
        background: `url(${Profile})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 0,
        height: "100%"
    },
    container: {
        paddingTop: theme.rootCard.padding.top,
        paddingBottom: theme.rootCard.padding.bottom
    },
    flexContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    icon: {
        color: `${theme.rootCard.contrast.color} !important`,

    },
    mainGrid: {
        height: "100%",
        width: "100%"
    }

}))

export default profileStyles