const deCoachingBusinessCoaching = {
    "coaching.offer.businessCoaching.title": "Business Coaching",
    "coaching.offer.businessCoaching.introduction": "Beim Business Coaching geht es um eine zielorientierte Beratung zu Themenbereichen des beruflichen Settings. Hierbei wird mittels des Coachings die Klienten/ Klientinnen bei der Maximierung ihrer Stärken unterstützt, um Ihre Leistung im Arbeitskontext weiter zu optimieren. Das Business Coaching wird hierbei auch sehr häufig im Gruppenkontext durchgeführt, um Teams dabei zu helfen eine maximale Synergie (optimale Zusammenarbeit) herzustellen und effizient zu arbeiten.",
    "coaching.offer.businessCoaching.myOfferings.introduction.1": "eine Leistungen befassen sich mit folgendem business- du organisationspsychologischem Spektrum:",
    "coaching.offer.businessCoaching.myOfferings.businessSingleCoaching.title": "Business Einzelcoaching",
    "coaching.offer.businessCoaching.myOfferings.businessSingleCoaching.description": "Hierbei geht es um das Eins –zu – Eins Coaching, in welchem zusammen mit dem Klienten/ der Klientin an der zuvor festgelegten Zielsetzung gearbeitet wird. Dabei können sowohl organisationsspezifische Themen, als auch Themen bezüglich des eigenen Lebensalltags angesprochen werden, um z. B. eine ideale Work – Life Balance zu erreichen.",
    "coaching.offer.businessCoaching.myOfferings.executiveCoaching.title": "Führungskräftecoaching",
    "coaching.offer.businessCoaching.myOfferings.executiveCoaching.description": "Bei diesem Coaching geht es um die Steigerung der Effizienz in einem Unternehmen bei gleichzeitiger Beibehaltung oder Verbesserung der Harmonie in einem entsprechenden Team. Dazu werden die Bereiche der Kommunikation, Zielformulierung und Verfolgung sowie Motivation und Entwicklung eines optimalen Arbeitsklimas in Bezug auf bereits bestehende individuelle Führungsqualitäten behandelt.",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.title": "BusinessWorkshops und Seminare",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.description.1": "Folgende Themenbereiche werden von mir angeboten: Teamentwicklung, Teamanalyse, Rhetorik, Empathische Kommunikation, Work – Life Balance, Mitarbeiterführung, Krisenmanagement etc.",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.description.2": "Weitere Themenbereiche können nach Absprache ebenfalls ausgearbeitet werden.",
    "coaching.offer.businessCoaching.myOfferings.teamCare.title": "Teambetreuung",
    "coaching.offer.businessCoaching.myOfferings.teamCare.description": "Bei einer Teambetreuung geht es um eine regelmäßige Unterstützung eines Teams oder einer gesamten Organisation. Hierbei werden Sitzungen in bestimmten zeitlichen Abständen angesetzt, um den Verlauf dieser Arbeitsgruppe evaluieren zu können, oder z. B. wiederkehrende Herausforderungen des Organisationsalltags offen ansprechen zu können.",
    "coaching.offer.businessCoaching.myOfferings.teambuilding.title": "Teambuilding",
    "coaching.offer.businessCoaching.myOfferings.teambuilding.description": "Beim Teambuilding geht es darum die Chemie der gesamten Abteilung (oder Kleingruppe) zu fördern, um diese im Wirtschaftsfeld erfolgreich nutzen zu können. Teambuilidngs können in den organisationsinternen Räumen stattfinden, oder nach Absprache auch extern gehalten werden.",
    "coaching.offer.businessCoaching.cooperation.description.1": "Eine Business Coaching - Sitzung beträgt in der Regel 50 Minuten kann jedoch nach Absprache auch länger dauern. Angebote wie das Teambuilding oder die Workshops und Seminare welche sich eventuell außerhalb des vereinbarten Coachingraumes befinden, können auch ganztags bzw. über mehrere Tage stattfinden. Der Coachingumfang, also die Anzahl der Sitzungen, ist individuell nach den Zielen und Wünschen wählbar.",
    "coaching.offer.businessCoaching.cooperation.description.2": "Es werden Räume zum Business Coaching von mir angeboten, es ist jedoch möglich sich auch in anderen Räumlichkeiten zu treffen, solang der gesicherte Rahmen für eine solche Betreuung gewährleistet werden kann.",
    "coaching.offer.businessCoaching.procedure.description": "Zu Beginn jeder Betreuung werden die Ziele und Wünsche der Organisation oder des Einzelnen in einem Erstgespräch besprochen und vereinbart. Hierbei werden die Formalitäten um die Betreuung herum erklärt und zusammen ein Betreuungskonzept entworfen. In den Folgesitzungen wird dann in regelmäßigen Abständen der Fortschritt des Teams oder des zu Betreuenden mittels Dokumentation und durch organisationspsychologische Testdiagnostik überprüft, um über die ganze Zeit effizient an den zuvor festgesetzten Zielen zu arbeiten."
}
export default deCoachingBusinessCoaching;