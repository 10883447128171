import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";

function SportPsychology() {
    const {t} = useTranslation();

    return (
        <>
            <Typography variant={"body1"}>{t("coaching.offer.sportPsychology.description.introduction")}</Typography>
            <Typography variant={"h5"}>{t("coaching.offer.sportPsychology.description.myOffers.title")}</Typography>
        </>
    )

}

export default SportPsychology;