import {makeStyles} from "@material-ui/core";


const coachingStyles = makeStyles(theme => ({
    fullCard: {
        minHeight: '70vh',
        height: "auto",
        display: "flex",
        background: theme.rootCard.main.background,
        color: theme.rootCard.main.color,
        paddingTop: theme.rootCard.padding.top,
        paddingBottom: theme.rootCard.padding.bottom,
    },
    container: {},
    flexContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    title: {
        marginBottom: "2rem"
    },
    mainGrid: {
        width: "100%",
        height: "calc(100% - " + theme.rootCard.padding.top + " - " + theme.rootCard.padding.bottom + " - 2rem)",
    }
}))

export default coachingStyles;