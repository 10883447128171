import czCoachingBusinessCoaching from "./coaching/czCoachingBusinessCoaching";
import czCoachingMotivationalSpeaking from "./coaching/czCoachingMotivationalSpeaking";
import czCoachingOtherCoaching from "./coaching/czCoachingOtherCoaching";
import czCoachingSportPsychology from "./coaching/czCoachingSportPsychology";

const czCoaching = {
    "coaching.cooperation": "Setting a spolupráce",
    "coaching.procedure": "Průběh",
    "coaching.targetGroups": "Moje cílové skupiny",
    "coaching.title": "Nabídky coachingu",
    "coaching.myOffers": "Moje nabídka",
    ...czCoachingBusinessCoaching,
    ...czCoachingMotivationalSpeaking,
    ...czCoachingOtherCoaching,
    ...czCoachingSportPsychology
}
export default czCoaching;