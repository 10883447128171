import React from "react";
import Main from "./main/Main";
import {Route} from "react-router-dom";
import DataProtection from "./dataProtection/DataProtection";
import Imprint from "./imprint/Imprint";
import OffersRouter from "./offers/OffersRouter";
import CV from "./cv/CV";


function MainRouter() {
    return (
        <>
            <Route path={"/offers"} component={OffersRouter}/>
            <Route path={"/cv"} component={CV}/>
            <Route path={"/imprint"} component={Imprint}/>
            <Route path={"/dataProtection"} component={DataProtection}/>
            <Route exact path={"/"} component={Main}/>
        </>
    )

}

export default MainRouter;