import enCoachingBusinessCoaching from "./coaching/enCoachingBusinessCoaching";
import enCoachingMotivationalSpeaking from "./coaching/enCoachingMotivationalSpeaking";
import enCoachingOtherCoaching from "./coaching/enCoachingOtherCoaching";
import enCoachingSportPsychology from "./coaching/enCoachingSportPsychology";

const enCoaching = {
    "coaching.title": "Coachings",
    "coaching.myOffers": "My offers",
    "coaching.cooperation": "Setting and collaboration",
    "coaching.procedure": "Procedure",
    "coaching.targetGroups": "My target groups",
    ...enCoachingBusinessCoaching,
    ...enCoachingMotivationalSpeaking,
    ...enCoachingOtherCoaching,
    ...enCoachingSportPsychology
}
export default enCoaching;