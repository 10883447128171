import enCoaching from "./en/enCoaching";
import enContact from "./en/enContact";
import enFooter from "./en/enFooter";
import enGeneral from "./en/enGeneral";
import enProfile from "./en/enProfile";
import enWelcome from "./en/enWelcome";
import enCv from "./en/enCv";

const en = {
    ...enCoaching,
    ...enContact,
    ...enFooter,
    ...enGeneral,
    ...enProfile,
    ...enWelcome,
    ...enCv
}
export default en;