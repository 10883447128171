import React, {useState} from "react";
import {
    Button,
    Container,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography
} from "@material-ui/core";
import ContactStyles from "./ContactStyles";
import socialMedias from "../../../../config/socialMedia";
import {useTranslation} from "react-i18next";

const targetEmail = "marek.pesicka@mperformance.net"

function Contact() {

    const contactStyles = ContactStyles();
    const [forename, setForename] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [message, setMessage] = useState("");
    const {t} = useTranslation();

    function generateMailTo() {
        const encForename = encodeURIComponent(forename)
        const encSurname = encodeURIComponent(surname)
        const encEmail = encodeURIComponent(email)
        const encTelephone = encodeURIComponent(telephone)
        const encMessage = encodeURIComponent(message)
        return "mailto:" +
            targetEmail +
            "?body=" + encMessage +
            "%0A%0A" +
            "Name:%20" + encForename + "%20" + encSurname +
            "%0AMeine%20Telefonnummer:%20" + encTelephone +
            "%0AMeine%20E-Mail:%20" + encEmail
    }

    return (
        <div className={contactStyles.fullCard}>
            <Container className={contactStyles.container} maxWidth={false}>
                <Typography variant={"h2"}>{t("contact.title")}</Typography>
                <Grid container spacing={3} className={contactStyles.mainGrid}>
                    <Grid item md={6}>
                        <Grid container spacing={5}>
                            <Grid item sm={6} className={contactStyles.formElement}>
                                <TextField id="forename" onChange={e => {
                                    setForename(e.target.value)
                                }} label={t("contact.forename")} variant="filled" value={forename} name={"forename"}
                                           fullWidth/>
                            </Grid>
                            <Grid item sm={6} className={contactStyles.formElement}>
                                <TextField id="surname" onChange={e => {
                                    setSurname(e.target.value)
                                }} label={t("contact.surname")} variant="filled" value={surname} name={"surname"}
                                           fullWidth/>
                            </Grid>
                            <Grid item sm={12} className={contactStyles.formElement}>
                                <TextField id="email" onChange={e => {
                                    setEmail(e.target.value)
                                }} label={t("contact.email")} variant="filled" value={email} name={"email"} fullWidth/>
                            </Grid>
                            <Grid item sm={12} className={contactStyles.formElement}>
                                <TextField id="telephone" onChange={e => {
                                    setTelephone(e.target.value)
                                }} label={t("contact.telephone")} variant="filled" value={telephone} name={"telephone"}
                                           fullWidth/>
                            </Grid>
                            <Grid item sm={12} className={contactStyles.formElement}>
                                <TextField id="message" onChange={e => {
                                    setMessage(e.target.value)
                                }} multiline rows={4} label={t("contact.message")} value={message} name={"message"}
                                           variant="filled" fullWidth/>
                            </Grid>
                            <Grid item sm={12}>
                                <Button variant={"contained"} color={"primary"} href={generateMailTo()}
                                        fullWidth>{t("button.send")}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <List>
                            {
                                socialMedias.map((socialMedia, index) => {
                                    return (
                                        <ListItem key={index} button component={Link} href={socialMedia.link}
                                                  target={"_blank"} rel={"noreferrer"} color={"inherit"}>
                                            <ListItemIcon>{socialMedia.icon}</ListItemIcon>
                                            <ListItemText primary={socialMedia.text}/>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        <Typography variant={"body1"} gutterBottom>{t("contact.pandemicInfo")}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Contact;