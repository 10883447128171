import deCoaching from "./de/deCoaching";
import deContact from "./de/deContact";
import deFooter from "./de/deFooter";
import deGeneral from "./de/deGeneral";
import deProfile from "./de/deProfile";
import deWelcome from "./de/deWelcome";
import deCV from "./de/deCV";

const de = {
    ...deCoaching,
    ...deContact,
    ...deFooter,
    ...deGeneral,
    ...deProfile,
    ...deWelcome,
    ...deCV

}
export default de;