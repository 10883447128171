const czCoachingBusinessCoaching = {
    "coaching.offer.businessCoaching.myOfferings.businessSingleCoaching.title": "Business coaching jednotlice",
    "coaching.offer.businessCoaching.myOfferings.businessSingleCoaching.description": "Zde se jedná o coaching jednotlivce, ve kterém s klientem/tkou pracujeme na společně vytyčeném cíli. Jedná se o specifická organizační témata, stejně jako o témata týkající se vlastního běžného pracovního dne, např. získání ideální rovnováhy mezi pracovním vytížením a osobním životem.",
    "coaching.offer.businessCoaching.myOfferings.executiveCoaching.title": "Coaching vedoucího pracovníka",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.title": "Business workshopy a semináře",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.description.1": "Nabízím následující tématické oblasti: rozvoj týmu, analýza týmu, rétorika, empatická komunikace, rovnováha mezi prací a osobním životem, vedení spolupracovníků, management krizí atd.",
    "coaching.offer.businessCoaching.myOfferings.businessWorkshop.description.2": "Podle požadavků zpracuji i další tématické oblasti.",
    "coaching.offer.businessCoaching.myOfferings.teamCare.title": "Péče o tým",
    "coaching.offer.businessCoaching.myOfferings.teambuilding.title": "Teambuilding",
    "coaching.offer.businessCoaching.myOfferings.teambuilding.description": "Při vytváření týmu se jedná o podpoření “společné chemie“ celého oddělení (nebo malé skupiny), aby se tato dala úspěšně využít v ekonomické oblasti. Teambuilding může probíhat ve vnitřních prostorách organizace, po dohodě i externě.",
    "coaching.offer.businessCoaching.myOfferings.introduction.1": "Moje nabídka se skládá z těchto částí:",
    "coaching.offer.businessCoaching.cooperation.description.2": "",
    "coaching.offer.businessCoaching.title": "Business coaching",
    "coaching.offer.businessCoaching.introduction": "Business coaching zahrnuje cílově orientované konzultace podle různých tématických oblastí profesionálního sezení. Prostřednictvím coachingu bude maximálně podpořen a optimalizován  potenciál klientů/tek v pracovním kontextu. Business coaching je prováděn velmi často skupinovou formou, aby byla podpořena spolupráce týmu a její efektivní využití při práci.",
    "coaching.offer.businessCoaching.myOfferings.executiveCoaching.description": "U tohoto coachingu se jedná o zvýšení efektivity společnosti při současném zachování nebo zlepšení harmonie týmu. Jsou zpracovávána témata týkající se oblasti komunikace, formulování cíle, kontrolování, stejně jako motivace a vývoj optimálního pracovního prostředí ve vztahu k již existujícím individuálním kvalitám vedení.",
    "coaching.offer.businessCoaching.myOfferings.teamCare.description": "Péče o tým představuje pravidelnou podporu týmu nebo celé organizace. Budou naplánována sezení v určitých pravidelných intervalech, aby mohl být např. vyhodnocen chod a vývoj pracovní skupiny nebo prodiskutovány stále se opakujícící výzvy na organizaci běžného pracovního dne.",
    "coaching.offer.businessCoaching.cooperation.description.1": "Business coaching – sezení činí obvykle 50 minut, po dohodě může trvat déle. Nabídky, jako teambuilding nebo workshopy a semináře, které probíhají většinou v externích prostorách, mohou trvat jeden nebo několik dnů. Objem coachingu, tedy počet sezení je individuální, volitelný podle cílů a přání.\nPro business coaching nabízím vlastní prostory,  ale je možné se setkávat i v jiných prostorách, které odpovídají potřebným požadavkům.",
    "coaching.offer.businessCoaching.procedure.description": "Na začátku každého coachingu, při prvním rozhovoru budou prodiskutovány a sjednoceny cíle a přání organizace nebo jednotlivce. Zde budou vyjasněny formality a vytvořen koncept spolupráce. Na následujících sezeních jsou v pravidelných intervalech na základě dokumentace a organizačně psychologické testovací diagnostiky ověřeny pokroky týmu nebo jednotlivce, aby mohly být účinně zpracovány k dosažení předem vytyčených cílů. "
}
export default czCoachingBusinessCoaching;