import deCoachingBusinessCoaching from "./coaching/deCoachingBusinessCoaching";
import deCoachingMotivationalSpeaking from "./coaching/deCoachingMotivationalSpeaking";
import deCoachingOtherCoaching from "./coaching/deCoachingOtherCoaching";
import deCoachingSportPsychology from "./coaching/deCoachingSportPsychology";

const deCoaching = {
    "coaching.title": "Coachingangebote",
    "coaching.myOffers": "Meine Angebote",
    "coaching.cooperation": "Setting und Zusammenarbeit",
    "coaching.procedure": "Ablauf",
    "coaching.targetGroups": "Meine Zielgruppen",
    ...deCoachingBusinessCoaching,
    ...deCoachingMotivationalSpeaking,
    ...deCoachingOtherCoaching,
    ...deCoachingSportPsychology
}
export default deCoaching;