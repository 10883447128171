import React from "react";
import WelcomeStyles from "./OpenerImageStyles";

function OpenerImage(){

const welcomeStyles = WelcomeStyles()

    return(
        <div className={welcomeStyles.fullCard} >

        </div>
    )

}
export default OpenerImage;