import React from "react";
import CoachingOfferings from "../../../config/CoachingOfferings";
import SubpageWrapper from "../../../commons/subpageWrapper/SubpageWrapper";
import {useTranslation} from "react-i18next";
import OffersStyles from "../OffersStyles";
import {Typography} from "@material-ui/core";
import MultipleAccordions from "../../../commons/multipleAccordions/MultipleAccordions";

function SportPsychology() {


    const allOfferings = CoachingOfferings();
    const offering = allOfferings.find((entity) => entity.id === "sportPsychology")
    const {t} = useTranslation();
    const offersStyles = OffersStyles();
    const accordionContent = [
        {
            title: t("coaching.offer.sportPsychology.myOfferings.sportPsychologySingleCoaching.title"),
            key: "sportPsychologySingleCoaching",
            descriptions: [t("coaching.offer.sportPsychology.myOfferings.sportPsychologySingleCoaching.description")]
        },
        {
            title: t("coaching.offer.sportPsychology.myOfferings.dataAnalytics.title"),
            key: "dataAnalytics",
            descriptions: [t("coaching.offer.sportPsychology.myOfferings.dataAnalytics.description")]
        },
        {
            title: t("coaching.offer.sportPsychology.myOfferings.trainingCare.title"),
            key: "trainingCare",
            descriptions: [
                t("coaching.offer.sportPsychology.myOfferings.trainingCare.description"),
            ]
        },
        {
            title: t("coaching.offer.businessCoaching.myOfferings.teamCare.title"),
            key: "teamCare",
            descriptions: [t("coaching.offer.businessCoaching.myOfferings.teamCare.description")]
        },
        {
            title: t("coaching.offer.sportPsychology.myOfferings.workshops.title"),
            key: "workshops",
            descriptions: [t("coaching.offer.sportPsychology.myOfferings.workshops.description")]
        },
        {
            title: t("coaching.offer.sportPsychology.myOfferings.teambuilding.title"),
            key: "teambuilding",
            descriptions: [t("coaching.offer.sportPsychology.myOfferings.teambuilding.description")]
        },
        {
            title: t("coaching.offer.sportPsychology.myOfferings.coachTheCoach.title"),
            key: "coachTheCoach",
            descriptions: [t("coaching.offer.sportPsychology.myOfferings.coachTheCoach.description")]
        },
        {
            title: t("coaching.offer.sportPsychology.myOfferings.parentalCoaching.title"),
            key: "parentalCoaching",
            descriptions: [t("coaching.offer.sportPsychology.myOfferings.parentalCoaching.description")]
        }
    ]
    return (
        <SubpageWrapper title={offering.title} backgroundImage={offering.image}
                        backgroundPosition={offering.imagePosition}>
            <Typography variant={"body1"} gutterBottom>{t("coaching.offer.sportPsychology.introduction")}</Typography>
            <Typography variant={"h3"} gutterBottom
                        className={offersStyles.heading}>{t("coaching.myOffers")}</Typography>

            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.sportPsychology.myOfferings.introduction.1")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.sportPsychology.myOfferings.introduction.2")}</Typography>


            <div className={offersStyles.accordionWrapper}>
                <MultipleAccordions content={accordionContent}/>
            </div>

            <Typography variant={"h3"} gutterBottom
                        className={offersStyles.heading}>{t("coaching.cooperation")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.sportPsychology.cooperation.description.1")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.sportPsychology.cooperation.description.2")}</Typography>

            <Typography variant={"h3"} gutterBottom
                        className={offersStyles.heading}>{t("coaching.procedure")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.sportPsychology.procedure.description")}</Typography>

        </SubpageWrapper>
    )

}

export default SportPsychology;