import React from "react";
import {Typography} from "@material-ui/core";
import ScrollToTop from "../../commons/scrollToTop/ScrollToTop";
import DataProtectionStyles from "./DataProtectionStyles";
import SubpageWrapper from "../../commons/subpageWrapper/SubpageWrapper";
import WelcomeImage from "../../assets/img/welcomeImage.jpg";

function DataProtection() {

    const dataProtectionStyles = DataProtectionStyles();

    return (
        <ScrollToTop>
            <SubpageWrapper title={"Datenschutz"} backgroundImage={WelcomeImage}>
                <Typography variant={"h3"} className={dataProtectionStyles.heading}
                            gutterBottom>Datenschutz</Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>1. Datenschutz auf
                    einen Blick</Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>Allgemeine
                    Hinweise</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren
                    personenbezogenen
                    Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen
                    Sie
                    pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema
                    Datenschutz
                    entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
                </Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>
                    Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                    k&ouml;nnen
                    Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
                    Datenschutzerkl&auml;rung
                    entnehmen.</Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>Wie erfassen wir
                    Ihre Daten?</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich
                    z.&nbsp;B. um Daten
                    handeln, die Sie in ein Kontaktformular eingeben.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere
                    IT-Systeme erfasst.
                    Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des
                    Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
                    betreten.
                </Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>Wof&uuml;r nutzen
                    wir Ihre Daten?</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
                    gew&auml;hrleisten. Andere Daten
                    k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
                </Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>Welche Rechte
                    haben Sie bez&uuml;glich Ihrer Daten?</Typography>
                <Typography variant={"body1"} gutterBottom>Sie haben jederzeit das
                    Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
                    personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder
                    L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt
                    haben,
                    k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben
                    Sie
                    das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der
                    zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns
                    wenden.</Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>2. Hosting und
                    Content Delivery Networks (CDN)</Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>Externes
                    Hosting</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten,
                    die auf
                    dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
                    v.
                    a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
                    Namen,
                    Websitezugriffe und sonstige Daten, die &uuml;ber eine Website generiert werden, handeln.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren
                    potenziellen und bestehenden Kunden (Art. 6 Abs. 1
                    lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres
                    Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie
                    dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf
                    diese Daten befolgen.
                </Typography>
                <Typography variant={"body1"} gutterBottom>Wir setzen folgenden Hoster ein:</Typography>
                <Typography variant={"body1"} gutterBottom>Strato AG<br/>
                    Pascalstra&szlig;e 10<br/>
                    10587 Berlin
                </Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>Abschluss eines
                    Vertrages &uuml;ber
                    Auftragsverarbeitung</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Um die datenschutzkonforme Verarbeitung zu gew&auml;hrleisten, haben wir einen Vertrag &uuml;ber
                    Auftragsverarbeitung mit unserem Hoster geschlossen.
                </Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>
                    3. Allgemeine Hinweise und Pflicht&shy;informationen
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading}
                            gutterBottom>Datenschutz</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Die Betreiber
                    dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
                    personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                    dieser Datenschutzerkl&auml;rung.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Wenn Sie diese
                    Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind
                    Daten,
                    mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
                    Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie nutzen.
                    Sie
                    erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
                </Typography>
                <Typography variant={"body1"} gutterBottom>Wir
                    weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per
                    E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
                    durch Dritte ist nicht m&ouml;glich.
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>Hinweis zur
                    verantwortlichen Stelle</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Marek Pesicka<br/>
                    Friedrich-Ebert-Stra&szlig;e 18<br/>
                    38440<br/>
                    Wolfsburg
                </Typography>

                <Typography variant={"body1"} gutterBottom>
                    Telefon: +4953611815166 <br/>
                    E-Mail: marek.pesicka@mperformance.de</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Verantwortliche Stelle ist die nat&uuml;rliche oder
                    juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der
                    Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.)
                    entscheidet.
                </Typography>

                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading}
                            gutterBottom>Speicherdauer</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt wurde,
                    verbleiben
                    Ihre personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt.
                    Wenn
                    Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung
                    widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich
                    zul&auml;ssigen&nbsp; Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben (z.B.
                    steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
                    L&ouml;schung
                    nach Fortfall dieser Gr&uuml;nde.
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>Widerruf Ihrer
                    Einwilligung zur Datenverarbeitung</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
                    ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung
                    jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung
                    bleibt vom Widerruf unber&uuml;hrt.
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>
                    Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art.
                    21 DSGVO)
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE
                    JEDERZEIT
                    DAS
                    RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
                    IHRER
                    PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
                    GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                    ENTNEHMEN
                    SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
                    PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
                    SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
                    FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER
                    VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
                    BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                    PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS
                    PROFILING,
                    SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
                    PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                    NACH ART. 21 ABS. 2 DSGVO).
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>
                    Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei
                    einer
                    Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres
                    Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht
                    unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>Recht auf
                    Daten&shy;&uuml;bertrag&shy;barkeit</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                    Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an einen
                    Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die
                    direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
                    soweit es technisch machbar ist.
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>SSL- bzw.
                    TLS-Verschl&uuml;sselung</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz
                    der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an
                    uns
                    als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte
                    Verbindung
                    erkennen Sie daran, dass die Adresszeile des Browsers
                    von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
                    Browserzeile.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an
                    uns &uuml;bermitteln, nicht
                    von Dritten mitgelesen werden.
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>Auskunft,
                    L&ouml;schung und Berichtigung</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                    jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen
                    Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
                    Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
                    personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>Recht auf
                    Einschr&auml;nkung der Verarbeitung</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung
                    Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit an uns wenden. Das
                    Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:
                </Typography>
                <ul>
                    <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
                        ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
                        Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
                        personenbezogenen Daten zu verlangen.
                    </li>
                    <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht,
                        k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
                    </li>
                    <li>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur
                        Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben
                        Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
                        personenbezogenen Daten zu verlangen.
                    </li>
                    <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung
                        zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
                        Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
                        personenbezogenen Daten zu verlangen.
                    </li>
                </ul>
                <Typography variant={"body1"} gutterBottom>
                    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                    eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung
                    abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder
                    Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen
                    oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der
                    Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
                </Typography>
                <Typography variant={"h4"} className={dataProtectionStyles.smallHeading} gutterBottom>4. Datenerfassung
                    auf dieser Website</Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading}
                            gutterBottom>Server-Log-Dateien</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                    Server-Log-Dateien,
                    die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:
                </Typography>
                <ul>
                    <li>Browsertyp und Browserversion</li>
                    <li>verwendetes Betriebssystem</li>
                    <li>Referrer URL</li>
                    <li>Hostname des zugreifenden Rechners</li>
                    <li>Uhrzeit der Serveranfrage</li>
                    <li>IP-Adresse</li>
                </ul>
                <Typography variant={"body1"} gutterBottom>
                    Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                    Websitebetreiber hat
                    ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner
                    Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst werden.
                </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading}
                            gutterBottom>Kontaktformular</Typography>
                <Typography variant={"body1"} gutterBottom>
                    Wenn Sie
                    uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
                    inklusive
                    der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von
                    Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
                    weiter.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Die Verarbeitung dieser Daten erfolgt
                    auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
                    Vertrags
                    zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In
                    allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                    effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                    Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</Typography> <Typography
                variant={"body1"} gutterBottom>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
                bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
                f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                Zwingende gesetzliche Bestimmungen &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben
                unber&uuml;hrt.
            </Typography>
                <Typography variant={"h5"} className={dataProtectionStyles.miniHeading} gutterBottom>
                    Anfrage per E-Mail, Telefon oder Telefax
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren,
                    wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum
                    Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir
                    nicht ohne Ihre Einwilligung weiter.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre
                    Anfrage mit
                    der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher
                    Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf
                    unserem
                    berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
                    lit.
                    f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
                    wurde.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Die von Ihnen an uns per
                    Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern,
                    Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
                    entf&auml;llt
                    (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche
                    Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
                    unber&uuml;hrt.
                </Typography>
                <Typography variant={"body1"} gutterBottom>Quelle: <a
                    href="https://www.e-recht24.de">https://www.e-recht24.de</a></Typography>
            </SubpageWrapper>
        </ScrollToTop>
    )

}

export default DataProtection;