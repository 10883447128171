import {makeStyles} from "@material-ui/core";

const footerHeight = "20vh"

const ContactStyles = makeStyles(theme => ({
    fullCard: {
        minHeight: footerHeight,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    container: {
        padding: "2rem"
    },
    gridItem: {
        width: "100%",
        margin: 0
    },
    logo: {
        width: "auto",
        maxHeight: "3rem"
    },
    legal: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center"
    },
    legalItem: {
        margin: ".25rem",
        textDecoration: "none !important",
        color: "inherit"
    },
    secondaryRoot: {
        paddingTop: "1rem",
        paddingBottom: "1rem",
        background: "#1f3750",
        color: "#BDBDBD",
    },
    secondaryContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    partnerLogo: {
        height: "calc(" + footerHeight + " / 1.75)",
        width: "auto",
        maxWidth: "calc(" + footerHeight + " / 1.75)"
    },
    partnerWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "1rem",
        flexWrap: "wrap"
    },
    partnerLink: {
        padding: "2rem"
    }
}))

export default ContactStyles;