import {makeStyles} from "@material-ui/core";
import WelcomeImage from "../../../../assets/img/welcomeImage.jpg";

const welcomeStyles = makeStyles(theme => ({
    fullCard: {
        minHeight: '90vh',
        height: 'auto',
        [theme.breakpoints.up('sm')]: {
            backgroundImage: `linear-gradient(to right, rgba(0,0,0,.75), rgba(0,0,0,.6), rgba(0,0,0,0)),url(${WelcomeImage})`,
        },
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `linear-gradient(to right, rgba(0,0,0,.75), rgba(0,0,0,.75)),url(${WelcomeImage})`,
        },
        backgroundPosition: "right",
        backgroundSize: "cover",
        //background: theme.rootCard.contrast.background,
        color: theme.rootCard.contrast.color,
        display: "flex",
    },
    imagePaper: {
        background: `url(${WelcomeImage})`,
        backgroundSize: "cover",
        backgroundPosition: "right",
        height: "100%",
        width: "100%"
    },
    container: {
        paddingTop: theme.rootCard.padding.top,
        paddingBottom: theme.rootCard.padding.bottom,
    },
    textBlock: {
        marginBottom: "2rem"
    },
    verticalFlex: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
    },
    mainGrid: {
        height: "100%",
    }
}))

export default welcomeStyles