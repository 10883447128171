import {AlternateEmail, Instagram, LinkedIn, Phone} from "@material-ui/icons";
import React from "react";

const socialMedias = [
    {
        icon: <AlternateEmail/>,
        text: "marek.pesicka@mperformance.net",
        link: "mailto:marek.pesicka@mperformance.net"
    }, {
        icon: <Phone/>,
        text: "+49 5361 1815166",
        link: "tel:+4953611815166"
    }, {
        icon: <LinkedIn/>,
        text: "Marek Pesicka, M.Sc.",
        link: "https://www.linkedin.com/in/marek-pesicka-m-sc-3a557860/"
    }, {
        icon: <Instagram/>,
        text: "mp_mperformance",
        link: "https://www.instagram.com/mp_mperformance/"
    },

]


export default socialMedias;