import React from "react";
import WelcomeStyles from "./WelcomeStyles";
import {Container, Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";

function Welcome() {

    const welcomeStyles = WelcomeStyles();
    const {t} = useTranslation();

    return (
        <div className={welcomeStyles.fullCard}>
            <Container className={welcomeStyles.container} maxWidth={false}>
                <Grid container spacing={4} className={welcomeStyles.mainGrid}>
                    <Grid item sm={6}>
                        <div className={welcomeStyles.verticalFlex}>
                            <div>
                                <Typography variant={"h3"}>{t("welcome.welcome")}</Typography>
                                <Typography variant={"subtitle1"} className={welcomeStyles.textBlock}>Maximum
                                    Performance</Typography>
                                <Typography variant={"body1"} paragraph={true}>
                                    {t("welcome.paragraph1")}
                                </Typography>
                                <Typography variant={"body1"} paragraph={true}>
                                    {t("welcome.paragraph2")}
                                </Typography>
                                <Typography variant={"body1"} paragraph={true}>
                                    {t("welcome.paragraph3")}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant={"h4"}>Marek Pešička, M. Sc.</Typography>
                                <Typography variant={"subtitle1"}>{t("welcome.founder")}</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item sm={6}>
                    </Grid>
                </Grid>

            </Container>
        </div>
    )

}

export default Welcome;