import React from "react";
import CoachingOfferings from "../../../config/CoachingOfferings";
import SubpageWrapper from "../../../commons/subpageWrapper/SubpageWrapper";
import {useTranslation} from "react-i18next";
import OffersStyles from "../OffersStyles";
import {Card, Grid, Typography} from "@material-ui/core";
import {School, Sports, Work} from "@material-ui/icons";

function MotivationalSpeaking() {


    const allOfferings = CoachingOfferings();
    const offering = allOfferings.find((entity) => {
        return entity.id === "motivationalSpeaking"
    })
    const {t} = useTranslation();
    const offersStyles = OffersStyles();

    return (
        <SubpageWrapper title={offering.title} backgroundImage={offering.image}
                        backgroundPosition={offering.imagePosition}>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.motivationalSpeaking.introduction")}</Typography>

            <Typography variant={"h3"} gutterBottom
                        className={offersStyles.heading}>{t("coaching.targetGroups")}</Typography>
            <div className={offersStyles.spotlightWrapper}>
                <Grid container spacing={5} className={offersStyles.spotlightGrid} justify={"center"}>
                    <Grid item sm={4}>
                        <Card className={offersStyles.spotlightCard}>
                            <School className={offersStyles.spotlightIcon}/>
                            <Typography
                                variant={"body1"}>{t("coaching.offer.motivationalSpeaking.targetGroups.school")}</Typography>
                        </Card>
                    </Grid>
                    <Grid item sm={4}>
                        <Card className={offersStyles.spotlightCard}>
                            <Work className={offersStyles.spotlightIcon}/>
                            <Typography
                                variant={"body1"}>{t("coaching.offer.motivationalSpeaking.targetGroups.work")}</Typography>
                        </Card>
                    </Grid>
                    <Grid item sm={4}>
                        <Card className={offersStyles.spotlightCard}>
                            <Sports className={offersStyles.spotlightIcon}/>
                            <Typography
                                variant={"body1"}>{t("coaching.offer.motivationalSpeaking.targetGroups.sports")}</Typography>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Typography variant={"h3"} gutterBottom
                        className={offersStyles.heading}>{t("coaching.procedure")}</Typography>
            <Typography variant={"body1"}
                        gutterBottom>{t("coaching.offer.motivationalSpeaking.procedure.description")}</Typography>

        </SubpageWrapper>
    )


}

export default MotivationalSpeaking;