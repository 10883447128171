const czCoachingSportPsychology = {
    "coaching.offer.sportPsychology.myOfferings.introduction.1": "Všechny nabízené služby jsou zaměřené na výkonnostně zaměřené sportovce/tkyně. Minimální věk pro sportovní coaching se neuvádí.",
    "coaching.offer.sportPsychology.myOfferings.introduction.2": "Nabízím následující typy coachingu:",
    "coaching.offer.sportPsychology.myOfferings.sportPsychologySingleCoaching.title": "Sportovně psychologický coaching jedntlivce",
    "coaching.offer.sportPsychology.myOfferings.dataAnalytics.title": "Analýza dat a sportovně psychologická diagnostika",
    "coaching.offer.sportPsychology.myOfferings.trainingCare.title": "Doprovod, podpora u tréninků, tréninkových campů, soutěží",
    "coaching.offer.sportPsychology.myOfferings.trainingCare.description": "Při tréninku, soutěžích a podobných akcích bude klient/ka pozorován/a, analyzován/a a vyhodnocen/a z hlediska mentální výkonnosti. Celkové vyhodnocení proběhne ústní i písemnou formou.",
    "coaching.offer.sportPsychology.myOfferings.workshops.title": "Sportovně psychologické workshopy a semináře",
    "coaching.offer.sportPsychology.myOfferings.workshops.description": "Nabízím následující témata: trénování vytváření cílů, relaxační trénink, intervence při krizi, trénink koncentrace, zacházení s tlakem, imaginační trénink, management okolí, trénink všímavosti, trénink identifikace. Po dohodě zpracuji i další, Vámi navrhovaná témata.",
    "coaching.offer.sportPsychology.myOfferings.teambuilding.title": "Teambuilding",
    "coaching.offer.sportPsychology.myOfferings.coachTheCoach.title": "Coaching trenéra",
    "coaching.offer.sportPsychology.myOfferings.coachTheCoach.description": "Zde se budeme soustředit hlavně na optimalizaci psychosociální kompetence trenéra/ky. Všeobecným cílem této nabídky je maximalizace výkonu týmu nebo jednotlivce pomocí posílení vlastního potenciálu.",
    "coaching.offer.sportPsychology.myOfferings.parentalCoaching.title": "Coaching rodičů",
    "coaching.offer.sportPsychology.myOfferings.parentalCoaching.description": "Podpora sportovní kariéry vlastního dítěte je důležitým faktorem potencionálního zlepšení atleta/tky. Základem coachingu rodičů je vyrovnání se s tlakem při různých situacích nebo účinný zásah ve prospěch vlastního dítěte.",
    "coaching.offer.sportPsychology.cooperation.description.2": "",
    "coaching.offer.sportPsychology.title": "Sportovní psychologie",
    "coaching.offer.sportPsychology.introduction": "Sportovní psychologie je vědecký obor na rozmezí  psychologie, sportovních věd a medicíny. Jejím obsahem jsou výsledky zkoumání psychologických podkladů, sportovních dovedností a zejména jeho účinků na tréninková opatření, která mohou vést k optimalizaci sportovního výkonu  (Beckmann & Elbe, 2011). ",
    "coaching.offer.sportPsychology.myOfferings.sportPsychologySingleCoaching.description": "Jedná se o coaching jeden na jednoho, ve kterém společně s klientem/tkou pracujeme na předem vytyčeném cíli. Může se jednat i o sportovně specifická pohybová cvičení (např. technika vrhu nebo forhendu), která mohou být trénovaná i pomocí mentálních cvičení.",
    "coaching.offer.sportPsychology.myOfferings.dataAnalytics.description": "Při úplnosti určitých dat můžeme provést jejich statistické vyhodnocení. Předložená data jsou vyhodnocená matematicko-statistickými postupy,  jejichž výsledek je dán klientovi/tce k dispozici. Jedná se např. o vyhodnocení dat, jako je kalkulace úspěchu podle sestavení týmu nebo o vlastní vyhodnocení svých sportovně psychologických postupů.",
    "coaching.offer.sportPsychology.myOfferings.teambuilding.description": "Při vytváření týmu jde o posílení týmového ducha celé skupiny a jeho efektivního využití při soutěži. Pracovat můžeme ve vnitřních prostorách oddílu – skupiny nebo po dohodě i v externích prostorách.",
    "coaching.offer.sportPsychology.cooperation.description.1": "Sportovně psychologické sezení zahrnuje zpravidla 50 minut, po dohodě může trvat déle. Podpora při  tréninku, tréninkovém campu nebo soutěži může probíhat jeden nebo několik dnů. Objem coachingu, počet sezení je individuální podle cíle a přání atleta/tky. Pro sezení nabízím vlastní prostory, ale je možné se setkat i v jiných prostorách, které odpovídají potřebným požadavkům.",
    "coaching.offer.sportPsychology.procedure.description": "Na začátku každého coachingu jsou vytyčeny a prodiskutovány cíle a přání atleta/ky. Jsou objasněny formality a sjednoceny požadavky celého konceptu spolupráce. Na následujích sezeních jsou v pravidelných intervalech na základě dokumentace a sportovně psychologické testovací diagnostiky prodiskutovány a ověřeny pokroky atleta/ky, které účinně vedou k dosažení již určených cílů."
}
export default czCoachingSportPsychology;