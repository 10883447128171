import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";

function MultipleAccordions(props) {

    const {content} = props
    const [expanded, setExpanded] = useState(null);
    const expandPanel = (name) => (event, isExpanded) => {
        setExpanded(isExpanded ? name : null);
    }
    return content.map(entity => {
        return (
            <Accordion expanded={expanded === entity.key}
                       onChange={expandPanel(entity.key)}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>{entity.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {entity.descriptions.map(description => (
                            <Typography gutterBottom variant={"body1"}>{description}</Typography>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    })

}

export default MultipleAccordions;