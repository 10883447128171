const enCv = {
    "cv.tuDresden.text.1": "Doctoral studies at the faculty of medicine Carl Gustav Carus .",
    "cv.mentalExcellence.text.3": "Emphasis in equestrian and tennis",
    "cv.bvn.title": "Bundesverband der Vertragspsychotherapeuten Niedersachsen",
    "cv.armstrongUniversityPass.title": "Armstrong State University - PASS Lab",
    "cv.today": "today",
    "cv.tuDresden.text.2": "Desired title: Dr. rer. medic.",
    "cv.tuDresden.text.3": "Research on suicidality in professional soccer",
    "cv.dinklar.title": "Gesellschaft für Verhaltenstherapie Dinklar",
    "cv.diklar.text.1": "Psychological psychotherapist in training",
    "cv.tuBrunswick.title": "Technische Universität Braunschweig",
    "cv.mentalExcellence.title": "Center of Mental Excellence",
    "cv.mentalExcellence.text.1": "Training in sports psychological coaching in competitive sports",
    "cv.mentalExcellence.text.2": "Title: Sports psychologist",
    "cv.armstrongUniversity.title": "Armstrong State University (USA)",
    "cv.armstrongUniversity.text.1": "Title: Bachelor of Science in Psychology",
    "cv.armstrongUniversity.text.2": "Honors: Cum Laude tennis scholarship",
    "cv.arlingtonUniversity.title": "University of Texas at Arlington (USA)",
    "cv.kgsHemmingen.title": "KGS Hemmingen - Federal Training Center North of the German Tennis Federation",
    "cv.kgsHemmingen.text.1": "Title: High school diploma",
    "cv.maximumPerformance.title": "Maximum Performance - Freelance",
    "cv.maximumPerformance.text.1": "Founder and owner",
    "cv.awo.title": "AWO Psychiatriezentrum Königslutter",
    "cv.awo.text.1": "Psychologist on an acute psychiatric ward",
    "cv.bvn.text.1": "Co-opted board member",
    "cv.pemi.title": "PEMI Tennis Academy",
    "cv.pemi.text.1": "Co-Managing Director",
    "cv.ehrenbergKollegen.text.2.1": "Head of sports psychology",
    "cv.ehrenbergKollegen.text.1.1": "Voluntary internship",
    "cv.armstrongUniversityTennis.title": "Armstrong State University - Tennis teams",
    "cv.armstrongUniversityTennis.text.1": "Student-assistant coach",
    "cv.professionalSports.title": "Professional athletic career",
    "cv.professionalSports.text.1": "ITF and ATP",
    "cv.title": "Curriculum Vitae",
    "cv.tuBrunswick.text.1": "Title: Master of Science in Psychology",
    "cv.arlingtonUniversity.text.1": "Major: Psychology tennis scholarship",
    "cv.tuDresden.title": "Technische Universität Dresden",
    "cv.tuBrunswick.text.2": "Emphasis in clinical psychology",
    "cv.kgsHemmingen.text.2": "Emphasis in physical education and biology",
    "cv.ehrenbergKollegen.title": "Psychotherapiepraxis Ehrenberg & Kollegen",
    "cv.armstrongUniversityPass.text.1": "Research assistant with focus on psychological momentum in sports and anticipation of aggressive behavior in everyday life",
    "cv.tennisAchievements.atp.primary": "Nr. 888",
    "cv.tennisAchievements.atp.secondary": "ATP - Doubles world ranking",
    "cv.tennisAchievements.itf.primary": "Nr. 1564",
    "cv.tennisAchievements.itf.secondary": "ITF - Juniors world ranking",
    "cv.tennisAchievements.usChampionships.primary": "2x 3rd place",
    "cv.tennisAchievements.usChampionships.secondary": "US National championships",
    "cv.tennisAchievements.usSouthEastChampionships.primary": "4x champion",
    "cv.tennisAchievements.usSouthEastChampionships.secondary": "Sunbelt Conference Championships",
    "cv.tennisAchievements.secondHighestCzech.primary": "2nd Czech division",
    "cv.tennisAchievements.secondHighestCzech.secondary": "Competition experience",
    "cv.tennisAchievements.secondHighestGerman.primary": "2nd German division (indoors)",
    "cv.tennisAchievements.secondHighestGerman.secondary": "Competition experience",
    "cv.hockeyAchievements.germany.primary": "Nomination for under 15 national team selection",
    "cv.hockeyAchievements.germany.secondary": "Germany",
    "cv.hockeyAchievements.northGerman.primary": "5x champion",
    "cv.hockeyAchievements.northGerman.secondary": "Northern Germany championships in development teams",
    "cv.hockeyAchievements.studentNational.primary": "Junior - Bundesliga",
    "cv.hockeyAchievements.studentNational.secondary": "Competition experience"
}

export default enCv;