import czCoaching from "./cz/czCoaching";
import czContact from "./cz/czContact";
import czFooter from "./cz/czFooter";
import czGeneral from "./cz/czGeneral";
import czProfile from "./cz/czProfile";
import czWelcome from "./cz/czWelcome";
import czCv from "./cz/czCv";

const cz = {
    ...czCoaching,
    ...czContact,
    ...czFooter,
    ...czGeneral,
    ...czProfile,
    ...czWelcome,
    ...czCv

}
export default cz;