import {makeStyles} from "@material-ui/core";
import OpenerImage from "../../../../assets/img/openerImage.jpg";

const openerImageStyles = makeStyles(theme => ({
    fullCard: {
        height: "100vh",
        backgroundImage: `url(${OpenerImage})`,
        backgroundPosition: "50% 20%",
        backgroundSize: "cover"
    }
}))

export default openerImageStyles