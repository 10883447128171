import {makeStyles} from "@material-ui/core";

const CoachingCardStyles = makeStyles(theme => ({
    root: props => ({
        height: "20rem",
        width: "100%",
        backgroundImage: `linear-gradient(to top, rgba(0,0,0,.75), rgba(0,0,0,0)),url(${props.backgroundImage})`, /* W3C */
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: props.backgroundPosition,
        padding: "1rem",

    }),
    action: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        color: theme.palette.primary.contrastText,
        textDecoration: "none",
        height: "100%",
        width: "100%",
        textAlign: "left"
    }
}))

export default CoachingCardStyles;