import React from "react";
import {Container, Typography} from "@material-ui/core";
import SubpageWrapperStyles from "./SubpageWrapperStyles";
import Navigation from "../navigation/Navigation";
import Footer from "../footer/Footer";
import PropTypes from "prop-types";

function SubpageWrapper(props) {

    const subpageWrapperStyles = SubpageWrapperStyles({
        backgroundImage: props.backgroundImage,
        backgroundPosition: props.backgroundPosition
    })
    return (
        <div className={subpageWrapperStyles.root}>
            <Navigation window={props.window}/>
            <div className={subpageWrapperStyles.titleBar}>
                <Container>
                    <Typography variant={"h2"}>{props.title}</Typography>
                </Container>
            </div>
            <Container className={subpageWrapperStyles.container}>
                {props.children}
            </Container>
            <Footer/>
        </div>
    )

}

SubpageWrapper.propTypes = {
    backgroundImage: PropTypes.string.isRequired,
    window: PropTypes.object,
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
}

SubpageWrapper.defaultProps = {
    backgroundPosition: "center"
}

export default SubpageWrapper;